import React from "react";

import CroissanceViewsYtb from './YTB/CroissanceViewsYtb';
import TypesPublicationYtb from './YTB/TypesPublicationYtb';
import CroissanceFollowersYtb from './YTB/CroissanceFollowersYtb';
import CroissanceEngagementYtb from './YTB/CroissanceEngagementYtb';
import CroissanceTauxEngagementYtb from './YTB/CroissanceTauxEngagementYtb';

const PerformanceChartYTB = (props) => {

  return (
    <div>
      <CroissanceFollowersYtb state={props} />
      <CroissanceViewsYtb state={props} />
      <CroissanceEngagementYtb state={props} />
      <CroissanceTauxEngagementYtb state={props} />
      <TypesPublicationYtb state={props} />
    </div>
  );
};

export default PerformanceChartYTB;
