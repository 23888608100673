import React from "react";
import { useTranslation } from "react-i18next";
import Highcharts from "highcharts";
import html2canvas from "html2canvas";
import HighchartsReact from "highcharts-react-official";

const CroissanceFollowersYtb = (props) => {
    
    const { t } = useTranslation();

    const printChart = (event) => {
        event.preventDefault();

        let target = event.target;
        let parent = target.parentElement.parentElement;
        let name = parent.getAttribute("data-value");
        parent.classList.add("capture_style");

        html2canvas(parent, {
        onclone: (document) => {},
        }).then((canvas) => {
        const data = canvas.toDataURL("image/jpg");
        const link = document.createElement("a");

        if (typeof link.download === "string") {
            link.href = data;
            link.download = name + ".jpg";

            parent.classList.remove("capture_style");

            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } else {
            window.open(data);
        }
        });
    };

    const data = props?.state?.reportingData?.ytb;
    const benchmark_video = data?.benchmark?.bench_video_ytb;

    let options = {
        chart: {
          type: "column",
        },
        title: {
          text: t("Performance_report.audience_growth"),
          align: "left",
          margin: 50,
        },
        subtitle: {
          text: t("Benchmark.audience_growth_desc"),
          align: "left",
          margin: 10,
        },
        tooltip: {
          enabled: false,
        },
        xAxis: {
          labels: {
            enabled: false,
          },
          categories: [],
        },
        yAxis: {
          min: 0,
          title: {
            text: "",
          },
        },
        legend: {
          enabled: true,
        },
        credits: {
          enabled: false,
        },
        plotOptions: {
          series: {
            borderWidth: 0,
            dataLabels: {
              enabled: true,
            },
          },
        },
        series: [{}]
    };

    const arr_data_option = [];
    for (const bench_mark in benchmark_video) {
        // Vérifier la valeur de tt.page_id et définir la couleur en conséquence
        let color;
        if (benchmark_video[bench_mark].page_id === 426629) {
          //-- Orange
          color = "#f16e00";
        } else if (benchmark_video[bench_mark].page_id === 426631) {
          //-- Ooredoo
          color = "#ed1c24";
        } else if (benchmark_video[bench_mark].page_id === 426630) {
          //-- Tunisie
          color = "#001e8c";
        }
    
        const arr = {
          name: benchmark_video[bench_mark].name_page,
          data: [benchmark_video[bench_mark].followers],
          color: color,
        };
        arr_data_option.push(arr);
        
    }
    arr_data_option.map((entry, index) => {
      options.series.push(entry);
    });

    function remove_empty_value(arr) {
        var index;
        arr.some(function (obj, i) {
        return Object.keys(obj).length === 0 && obj.constructor === Object
            ? ((index = i), true)
            : false;
        });
        arr.shift(index);
        return index;
    }
    remove_empty_value(options.series);

    return (
      <div>
        <div className="highchart_block mt-8">
          <div data-value={t("Performance_report.audience_growth")} className="highchart_block1">
            <div data-html2canvas-ignore className="flex items-center justify-end">
              <button
                onClick={printChart}
                className="mx-5 w-5 h-5 print_capture"
              ></button>
            </div>

            <HighchartsReact highcharts={Highcharts} options={options} />

            <div className="capture_style"></div>
          </div>
        </div>
      </div>
    );
};

export default CroissanceFollowersYtb;
