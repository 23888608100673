import React from "react";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import Highcharts from "highcharts";
import html2canvas from "html2canvas";
import { AppContext } from "../../../utils";
import HighchartsReact from "highcharts-react-official";

import { Tooltip } from 'primereact/tooltip';
import { Dropdown } from "primereact/dropdown";
import DropdownImg from "../../../images/dropdown1.png";

const CroissanceTauxEngagementYtb = (props) => {
    
    const { t } = useTranslation();
    const [state] = React.useContext(AppContext);

    const status = [
        { name: t("Options.month"), code: "month" },
        { name: t("Options.day"), code: "day" },
    ];

    function convert_format_date(date_to_convert) {
        let date = new Date(date_to_convert);

        // Get year, month, and day part from the date
        let year = date.toLocaleString("default", { year: "numeric" });
        let month = date.toLocaleString("default", { month: "2-digit" });
        let day = date.toLocaleString("default", { day: "2-digit" });

        // Generate yyyy-mm-dd date string
        let formattedDate = year + "-" + month + "-" + day;
        return formattedDate;
    }

    const printChart = (event) => {
        event.preventDefault();

        let target = event.target;
        let parent = target.parentElement.parentElement;
        let name = parent.getAttribute("data-value");
        parent.classList.add("capture_style");

        html2canvas(parent, {
        onclone: (document) => {},
        }).then((canvas) => {
        const data = canvas.toDataURL("image/jpg");
        const link = document.createElement("a");

        if (typeof link.download === "string") {
            link.href = data;
            link.download = name + ".jpg";

            parent.classList.remove("capture_style");

            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } else {
            window.open(data);
        }
        });
    };

    function day_or_month(date_one, date_two) {
        let date1 = new Date(date_one);
        let date2 = new Date(date_two);

        let diffTime = Math.abs(date2 - date1);
        let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

        let type = " ";
        if (diffDays <= 31) {
        type = "day";
        } else {
        type = "month";
        }

        return type;
    }

    let date_form = convert_format_date(state.reportingPeriod[0].toDateString());
    let date_to = convert_format_date(state.reportingPeriod[1].toDateString());

    const [type_option, setType_option] = useState(
        day_or_month(date_form, date_to)
    );

    const [valueDropdownOption, setValueDropdownOption] = useState(
        day_or_month(date_form, date_to)
    );

    function sort_array(dates) {
        dates.sort(function (a, b) {
        let dateA = new Date(a);
        let dateB = new Date(b);
        return dateA - dateB;
        });
        return dates;
    }

    const my_date = props?.state?.data?.ytb ?? [];

    //-- Day
    const propsDay = Object.entries(my_date?.by_day ?? {});    

    const day_array = [];
    const day_array_taux_engagement = [];
    for (const [key, value] of Object.entries(propsDay)) {
        day_array.push(value[0]);
        
        day_array_taux_engagement.push(+value[1].taux_engagement_video ?? 0);
    }

    const day_taux_engagement = {
        name: "Taux engagement",
        data: day_array_taux_engagement,
    };

    function day_data_option() {
        options.series.push(day_taux_engagement);
        sort_array(day_array).map((entry, index) => {
        options.xAxis.categories.push(entry);
        });
    }

    //-- Month
    const propsMonth = Object.entries(my_date?.by_month ?? {});

    const month_array = [];
    const month_array_taux_engagement = [];
    for (const [key, value] of Object.entries(propsMonth)) {
        month_array.push(value[0]);
        
        month_array_taux_engagement.push(+value[1].total.taux_engagement_video ?? 0);
    }

    const month_taux_engagement = {
        name: "Taux engagement",
        data: month_array_taux_engagement,
    };

    function month_data_option() {
        options.series.push(month_taux_engagement);
        sort_array(month_array).map((entry, index) => {
            options.xAxis.categories.push(entry);
        });
    }

    let options = {
        credits: false,

        chart: {
        type: "spline",
        zoomType: "xy",
        style: {
            fontFamily: "Inter",
        },
        },
        title: {
        text: t("Performance_report.video_engagement_rate"),
        align: "left",
        margin: 50,
        },
        subtitle: {
        text: t("Performance_report.engagement_desc"),
        align: "left",
        margin: 50,
        },
        xAxis: {
        categories: [],
        accessibility: {
            description: "Months of the year",
        },
        },
        yAxis: {
        title: {
            text: "",
        },
        labels: {
            formatter: function () {                
                return this.value + "%";
            },
        },
        },
        tooltip: {
            crosshairs: true,
            shared: true,
            formatter: function() {                
                return `${this.x}:<br/> ${this?.points[0]?.point?.series?.name ?? ''} <b>${this.y}%</b>`;
            }
        },
        plotOptions: {
        spline: {
            lineWidth: 4,
            marker: {
            radius: 4,
            lineColor: "#666666",
            lineWidth: 1,
            enabled: false,
            },
        },
        },
        series: [{}],
    };

    //-- option
    type_option === "month" ? month_data_option() : day_data_option();
    const onOptionChange = (e) => {
        setValueDropdownOption(e.value);
        if (e.value === "month") setType_option("month");
        if (e.value === "day") setType_option("day");
    };

    function remove_empty_value(arr) {
        var index;
        arr.some(function (obj, i) {
        return Object.keys(obj).length === 0 && obj.constructor === Object
            ? ((index = i), true)
            : false;
        });
        arr.shift(index);
        return index;
    }
    remove_empty_value(options.series);

  return (
    <div>
      <div className="highchart_block">
        <div data-value={t("Performance_report.video_engagement_rate")} className="highchart_block1">
          <div data-html2canvas-ignore className="flex items-center">
                         
            {/* info */}
            <div className="ml-2">
                <Tooltip target=".custom-target-icon" />
                <i className="custom-target-icon pi pi-info-circle p-text-secondary p-overlay-badge text-[#596ae9]"
                    data-pr-tooltip="Le taux d'engagement est calculé en fonction du nombre de réactions (likes et commentaires) par rapport au nombre de vues. Un taux d'engagement élevé indique que de nombreuses personnes ayant visionné la vidéo ont également interagi activement avec elle."
                    data-pr-position="right"
                    data-pr-at="right+5 top"
                    data-pr-my="left center-2"
                    style={{ fontSize: '18px', cursor: 'pointer' }}>
                </i>
            </div>
            <div className="flex items-center ml-auto border rounded-md capitalize">
              <img src={DropdownImg} className="pl-2" alt="DropdownImg" />
              <Dropdown
                value={valueDropdownOption}
                options={status}
                onChange={onOptionChange}
                defaultValue={setType_option}
                optionLabel="name"
                optionValue="code"
                placeholder="Choisir"
                className="font-medium"
              />
            </div>

            <button
              onClick={printChart}
              className="mx-5 w-5 h-5 print_capture"
            ></button>
          </div>

          <HighchartsReact highcharts={Highcharts} options={options} />

          <div className="capture_style"></div>
        </div>
      </div>
    </div>
  );
};

export default CroissanceTauxEngagementYtb;
