import React, { useCallback, useState } from "react";
import WordCloud from "./WordCloud";
import { useTranslation } from "react-i18next";
import { AppContext, SocialIcon, useUtils, getNextColor } from "../../utils";
import { Link } from "react-router-dom";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import dayjs from "dayjs";
import html2canvas from "html2canvas";

import angryEmoji from "../../images/emojiAngry.png";
import emojiHaha from "../../images/emojiHaha.png";
import emojiLike from "../../images/emojiLike.png";
import emojiLove from "../../images/emojiLove.png";
import emojiSad from "../../images/emojiSad.png";
import emojiWow from "../../images/emojiWow.png";

import { TabView, TabPanel } from 'primereact/tabview';

import { ReactComponent as UpIcon } from "../../images/upIcon.svg";
import { ReactComponent as DownIcon } from "../../images/downIcon.svg";
import millify from "millify";

// const data = [
//   { name: "Photo", value: 500 },
//   { name: "Video", value: 300 }
// ];
let legends = [];
const COLORS = ["#596ae9", "#6955e6", "#22c4ac", "orange"];

const PieEngagement = (props) => {
  const socialType = props.data.socialType;
  const { t, i18n } = useTranslation();
  const [state, setState] = React.useContext(AppContext);

  const page_id = state.currentPage.page_id;

  const [color] = useState(getNextColor());

  const start_date = props?.data?.date_info?.start_date;
  const end_date = props?.data?.date_info?.end_date;

  let options = {
    credits: false,
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: "pie",
      width: 600,
      style: {
        fontFamily: "Inter",
      },
    },
    colors: COLORS,
    title: {
      text: "",
    },
    tooltip: {
      pointFormat: "<b>{point.percentage:.2f}%</b><br/>{point.y}",
    },
    accessibility: {
      point: {
        valueSuffix: "%",
      },
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: true,
          format: "<b>{point.name}</b>: {point.percentage:.2f} %",
        },
        size: "70%",
      },
    },
    series: [
      {
        innerSize: "50%",
        pie: {},
        colorByPoint: true,
        data: [],
      },
    ],
  };

  options.series[0].data = [];
  //console.log('props.cmpData.data =>', props.cmpData.data)
  props.cmpData.data.map((entry, index) => {
    //console.log(entry);
    options.series[0].data.push({
      name: entry.name,
      y: entry[props.cmpData.dataKey],
    });
  });

  const highchartsCallback = (chart) => {};

  let emojis = {
    posts_love: emojiLove,
    posts_likes: emojiLike,
    posts_angry: angryEmoji,
    posts_haha: emojiHaha,
    posts_sad: emojiSad,
    posts_wow: emojiWow,
  };

  let reactions = [];
  if (
    props.cmpData.dataKey == "posts_type_interactions" ||
    props.cmpData.dataKey == "posts_types"
  ) {
    Object.keys(emojis).forEach((el) => {
      reactions.push({ icon: emojis[el], value: props.data.total[el] });
    });
  }

  //console.log("reactions", reactions);

  const printChart = (event) => {
    event.preventDefault();

    let target = event.target;
    let parent = target.parentElement.parentElement;
    let name = parent.getAttribute("data-value");

    html2canvas(parent, {
      onclone: (document) => {},
    }).then((canvas) => {
      const data = canvas.toDataURL("image/jpg");
      const link = document.createElement("a");
      if (typeof link.download === "string") {
        link.href = data;
        link.download = name + ".jpg";

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        window.open(data);
      }
    });
  };
  
  const reel_total = props?.data?.post_type?.reels;
  
  const reel_engagement = reel_total.total.engagement;
  
  let like_value = props?.data?.total?.posts_likes;
  let reel_likes = reel_total.total.likes;
  if (socialType == 'facebook') {
    like_value += reel_likes;
  }
  
  let love_value = props?.data?.total?.posts_love;
  let reel_love = reel_total.total.love;
  if (socialType == 'facebook') {
    love_value += reel_love;
  }
  
  let haha_value = props?.data?.total?.posts_haha;
  let reel_hahah = reel_total.total.hahah;
  if (socialType == 'facebook') {
    haha_value += reel_hahah;
  }
  
  let angry_value = props?.data?.total?.posts_angry;
  let reel_angry = reel_total.total.angry;
  if (socialType == 'facebook') {
    angry_value += reel_angry;
  }
  
  let wow_value = props?.data?.total?.posts_wow;
  let reel_wow = reel_total.total.wow;
  if (socialType == 'facebook') {
    wow_value += reel_wow;
  }
  
  let sad_value = props?.data?.total?.posts_sad;
  let reel_sad = reel_total.total.sad;
  if (socialType == 'facebook') {
    sad_value += reel_sad;
  }
  
  let reaction_value = props?.data?.total?.posts_reactions;
  let reel_reaction = reel_likes + reel_love + reel_hahah + reel_angry + reel_wow + reel_sad;
  if (socialType == 'facebook') {
    reaction_value += reel_reaction;
  }
  
  let comment_value = props?.data?.total?.posts_comments;
  let reel_comments = reel_total.total.comments;
  if (socialType == 'facebook') {
    comment_value += reel_comments;
  }
  
  let share_value = props?.data?.total?.posts_shares;
  let reel_shares = reel_total.total.shares;
  if (socialType == 'facebook') {
    share_value += reel_shares;
  }

  const variation_reel = reel_total.variation.engagement;

  let variation_engagement_posts = props.data.total.previous_total.variation_engagement_posts ?? 0;
  if (socialType == 'facebook') {
    variation_engagement_posts += variation_reel;
  }

  const variation_count_posts =
    props.data.total.previous_total.variation_count_posts ?? 0;

  const previous_total = props.data.total.previous_total
    ? props.data.total.previous_total
    : 0;

  const previous_end_date = props.data.date_info.previous_end_day ?? 0;
  const previous_start_date = props.data.date_info.previous_start_day ?? 0;

  const variation_posts_photos = previous_total?.previous_posts_photos
    ? ((props.data.total.posts_types?.photo -
        previous_total.previous_posts_photos) /
        previous_total.previous_posts_photos) *
        100 ?? 0
    : 0;
  const variation_posts_videos = previous_total.previous_posts_videos
    ? ((props.data.total.posts_types?.video -
        previous_total.previous_posts_videos) /
        previous_total.previous_posts_videos) *
        100 ?? 0
    : 0;
  const variation_posts_textes = previous_total.previous_posts_textes
    ? ((props.data.total.posts_types?.texte -
        previous_total.previous_posts_textes) /
        previous_total.previous_posts_textes) *
        100 ?? 0
    : 0;

  const variation_posts_clicks = previous_total.previous_posts_clicks
    ? ((props.data.total.posts_clicks - previous_total.previous_posts_clicks) /
        previous_total.previous_posts_clicks) *
        100 ?? 0
    : 0;
  let variation_posts_reactions = previous_total.previous_reactions
    ? ((props.data.total.posts_reactions - previous_total.previous_reactions) /
        previous_total.previous_reactions) *
        100 ?? 0
    : 0;

  let var_reaction_reel = reel_total.variation.like + reel_total.variation.love + reel_total.variation.haha + reel_total.variation.angry + reel_total.variation.wow + reel_total.variation.sad;
  if (socialType == 'facebook') {
    variation_posts_reactions += var_reaction_reel;
  }
  
  let variation_posts_comments = previous_total.previous_posts_comments
    ? ((props.data.total.posts_comments -
        previous_total.previous_posts_comments) /
        previous_total.previous_posts_comments) *
        100 ?? 0
    : 0;

  let var_comment_reel = reel_total.variation.comment;
  if (socialType == 'facebook') {
    variation_posts_comments += var_comment_reel;
  }

  let variation_posts_shares = previous_total.previous_posts_shares
    ? ((props.data.total.posts_shares - previous_total.previous_posts_shares) /
        previous_total.previous_posts_shares) *
        100 ?? 0
    : 0;

  let var_share_reel = reel_total.variation.share;
  if (socialType == 'facebook') {
    variation_posts_shares += var_share_reel;
  }
  const variation_posts_other_clicks = previous_total.previous_other_clicks
    ? ((props.data.total.details_clicks.other_clicks -
        previous_total.previous_other_clicks) /
        previous_total.previous_other_clicks) *
        100 ?? 0
    : 0;
  const variation_posts_photo_view = previous_total.previous_photo_view
    ? ((props.data.total.details_clicks.photo_view -
        previous_total.previous_photo_view) /
        previous_total.previous_photo_view) *
        100 ?? 0
    : 0;
  const variation_posts_link_clicks = previous_total.previous_link_clicks
    ? ((props.data.total.details_clicks.link_clicks -
        previous_total.previous_link_clicks) /
        previous_total.previous_link_clicks) *
        100 ?? 0
    : 0;
  /*-- Total Engagement */
  const post_reach = props.data?.total?.posts_reach;
  const totalInteractions = props.data?.total?.posts_interactions || 0;
  const clicks = props.data?.total?.posts_type_interactions?.Clics || 0;
  const total = totalInteractions + clicks;
  
  //-- engagement
  let formattedTotal = isNaN(total) ? 0 : total.toLocaleString();
  if (socialType == 'facebook') {
    formattedTotal = isNaN(total) ? reel_engagement.toLocaleString() : (total + reel_engagement).toLocaleString();
  }

  let taux_eng_post = post_reach ? (total / post_reach) * 100 : 0;
  const [isNuageVisible, setIsNuageVisible] = useState(false);

  const handleToggleNuage = () => {
    
    setIsNuageVisible(!isNuageVisible);

    /*if (isNuageVisible) {
      setTimeout(() => {
        const nuageWord = document.querySelector('.nuage_word');
        nuageWord.classList.add('hidden'); // Remplacez 'myClass' par le nom de la classe que vous souhaitez ajouter
      }, 500);
    } */
  };

  const isArabic = (word) => {
    const arabicRegex = /[\u0600-\u06FF]/;
    return arabicRegex.test(word);
  };

  const getGroupIndex = (index) => Math.floor(index / 5);

  //-- getWordCloud
  const getWordCloud = props.data.nauge_word;
  const getWordCloudAll = props.data.nauge_word_all;
  // Convert object to array of key-value pairs
  const wordArray = getWordCloud ? Object.entries(getWordCloud) : [];

  
  const data_brand = state?.currentKpis?.brands;  
  const wordArrayManager = getWordCloudAll.manager ? Object.entries(getWordCloudAll.manager) : [];
  const wordArrayUnmanager = getWordCloudAll.unmanager ? Object.entries(getWordCloudAll.unmanager) : [];
  
  const brandData = data_brand[page_id];
  let brandName = '';
  if (brandData) {
      brandName = brandData.name;
      // Utilisez `brandName` ici.
  }
  const wordArraAll = [
    {  
        name: brandName,
        nauge: []
    },
  ];
  wordArraAll[0].nauge = wordArrayManager.map(([word, count]) => ({ word, count }));

    // Ajout des nouvelles données de wordArrayUnmanager
  wordArrayUnmanager.forEach(([id, wordData]) => {
    const brandData = data_brand[id]; 
    if (brandData) {
        const brandName = brandData.name;
        wordArraAll.push({
            name: brandName, // Ajoute le brandName
            nauge: Object.entries(wordData).map(([word, count]) => ({
                word: word,
                count: count
            }))
        });
    } else {
        console.error(`Brand data not found for id: ${id}`);
    }
  });
  

  // Sort the array by values in descending order
  const sortedWordArray = wordArray.sort((a, b) => b[1] - a[1]); 

  // Create an array of shuffled indices
  const shuffledIndices = Array.from(
    { length: sortedWordArray.length },
    (_, index) => index
  );
  for (let i = shuffledIndices.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffledIndices[i], shuffledIndices[j]] = [
      shuffledIndices[j],
      shuffledIndices[i],
    ];
  }
  
  const sortedWordAll = wordArraAll.forEach(item => {
    // Trier le tableau 'nauge' pour chaque élément de 'wordArraAll'
    item.nauge = item.nauge.sort((a, b) => b.count - a.count);
  });
  
  // Mélanger les indices et appliquer la logique de classement
  wordArraAll.forEach(item => {
    // Créer un tableau d'indices basé sur la longueur de 'nauge'
    const shuffledIndices = Array.from({ length: item.nauge.length }, (_, index) => index);

    // Mélanger les indices de manière aléatoire pour chaque "nauge" avec l'algorithme de Fisher-Yates
    for (let i = shuffledIndices.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffledIndices[i], shuffledIndices[j]] = [shuffledIndices[j], shuffledIndices[i]]; // Échange des indices
    }

    // Créer un tableau mélangé pour chaque "nauge" en utilisant les indices mélangés
    item.nauge = shuffledIndices.map(index => item.nauge[index]);
  });

  const renderTotal = () => {
    if (props.cmpData.dataKey == "posts_types") {
      return (
        <div>
          <div className="audience_total bg-rectangle rounded-[8px] mb-10">
            <div className="flex items-center">
              <div className="w-5/5">
                <span className="text-[#152935] text-[14px] font-['inter'] font-bold ">
                  Comportement de publication par type de contenu
                </span>
              </div>
            </div>
            <div className="flex items-center">
              <div className="w-2/5">
                <h2 className="text-[#344563] text-[14px] font-['inter']">
                  Total des posts publiés
                </h2>
              </div>
              <div className="w-3/5">
                <span className="text-[#152935] text-[24px] font-['inter'] font-extrabold">
                  {props.data?.total?.posts_count} Posts
                </span>
              </div>
            </div>

            <div className="flex items-center mt-9">
              <div className="w-1/5">
                <span className="text-[#344563] text-[14px] font-['inter']  font-bold">
                  {variation_count_posts
                    .toString()
                    .match(/^-?\d+(?:\.\d{0,3})?/)[0] || 0}
                  %
                </span>
              </div>
              <div className="w-4/5">
                <span className="text-[#344563] text-[14px] font-['inter'] capitalize">
                  comparé à
                  <span className="font-bold">
                    {" "}
                    {previous_end_date} - {previous_start_date}
                  </span>
                </span>
              </div>
            </div>
          </div>

          <div>
            <div className="text-xs">
              <div className="flex items-center">
                <span className=""></span>
                <div className="ml-2 flex capitalize">Photos :</div>
                <span className="title_1 flex items-center ml-auto">
                  {props.data.total.posts_types?.photo
                    ? props.data.total.posts_types.photo
                    : 0}
                  {variation_posts_photos > 0 ? (
                    <div
                      style={{
                        width: 15,
                        marginLeft: 15,
                        marginRight: 5,
                        fill: "green",
                      }}
                    >
                      <UpIcon />
                    </div>
                  ) : (
                    <div
                      style={{
                        width: 15,
                        marginLeft: 15,
                        marginRight: 5,
                        fill: "red",
                      }}
                    >
                      <DownIcon />
                    </div>
                  )}
                  {variation_posts_photos.toFixed(2) || 0}%
                </span>
              </div>
              <hr className="mb-4 mt-1" />
            </div>
            <div className="text-xs">
              <div className="flex items-center">
                <span className=""></span>
                <div className="ml-2 flex capitalize">Vidéos :</div>
                <span className="title_1 flex items-center ml-auto">
                  {props.data.total.posts_types?.video ?? 0}
                  {variation_posts_videos > 0 ? (
                    <div
                      style={{
                        width: 15,
                        marginLeft: 15,
                        marginRight: 5,
                        fill: "green",
                      }}
                    >
                      <UpIcon />
                    </div>
                  ) : (
                    <div
                      style={{
                        width: 15,
                        marginLeft: 15,
                        marginRight: 5,
                        fill: "red",
                      }}
                    >
                      <DownIcon />
                    </div>
                  )}
                  {variation_posts_videos.toFixed(2) || 0}%
                </span>
              </div>
              <hr className="mb-4 mt-1" />
            </div>
            <div className="text-xs">
              <div className="flex items-center">
                <span className=""></span>
                <div className="ml-2 flex capitalize">Textes :</div>
                <span className="title_1 flex items-center ml-auto">
                  {props.data.total.posts_types?.texte ?? 0}
                  {variation_posts_textes > 0 ? (
                    <div
                      style={{
                        width: 15,
                        marginLeft: 15,
                        marginRight: 5,
                        fill: "green",
                      }}
                    >
                      <UpIcon />
                    </div>
                  ) : (
                    <div
                      style={{
                        width: 15,
                        marginLeft: 15,
                        marginRight: 5,
                        fill: "red",
                      }}
                    >
                      <DownIcon />
                    </div>
                  )}
                  {variation_posts_textes.toFixed(2) || 0}%
                </span>
              </div>
              <hr className="mb-4 mt-1" />
            </div>
          </div>
        </div>
      );
    }
    if (props.cmpData.dataKey == "posts_type_interactions") {
      return (
        <div>
          <div className="audience_total bg-rectangle rounded-[8px] mb-10 relative">
            <button
              onClick={handleToggleNuage}
              className="btn_nuage flex items-center text-[#6941c6] text-[14px] font-['inter'] font-bold absolute top-[10px] right-[10px]"
            >
              {t("Performance_report.keyword_trend")}
              <span className="ml-1 text-[18px]">+</span>
            </button>

            <div className="flex items-center">
              <div className="w-5/5">
                <span className="text-[#152935] text-[14px] font-['inter'] font-bold ">
                  {t("Performance_report.engagement_metrics")}
                </span>
              </div>
            </div>

            <div className="flex items-center">
              <h2 className="text-[#344563] text-[14px] font-['inter'] mr-5">
                {t("Performance_report.total_engagement")}
              </h2>
              <span className="text-[#152935] text-[24px] font-['inter'] font-extrabold">
                {formattedTotal}
              </span>
            </div>

            <div className="flex items-center">
              <h2 className="text-[#344563] text-[14px] font-['inter'] mr-5">
                {t("Performance_report.post_engagement_rate")}
              </h2>
              <span className="text-[#152935] text-[24px] font-['inter'] font-extrabold">
                {taux_eng_post.toFixed(3) || 0}%
              </span>
            </div>

            {socialType == "facebook" ? (
              <div className="flex items-center mt-9">
                <div className="w-1/5">
                  <span className="text-[#344563] text-[14px] font-['inter']  font-bold">
                    {variation_engagement_posts
                      .toString()
                      .match(/^-?\d+(?:\.\d{0,3})?/)[0] || 0}
                    %
                  </span>
                </div>
                <div className="w-4/5">
                  <span className="text-[#344563] text-[14px] font-['inter']">
                    {t("Options.compared")}
                    <span className="font-bold">
                      {" "}
                      {previous_end_date} - {previous_start_date}
                    </span>
                  </span>
                </div>
              </div>
            ) : null}
          </div>

          <div>
            <div className="text-xs">
              <div className="flex items-center">
                <span className=""></span>
                <div className="ml-2 flex capitalize">
                  {t("Performance_report.reactions")}:
                  <div className="flex mx-8 gap-2 justify-center">
                    <div className="flex items-center">
                      <img src={emojiLike} alt="emojiLike" className="mr-1.5" />
                      <p>
                        {like_value?.toLocaleString() ?? 0}
                      </p>
                    </div>
                    {socialType == "facebook" ? (
                      <div className="flex">
                        <div className="flex items-center">
                          <img
                            src={emojiLove}
                            alt="emojiLove"
                            className="mr-1.5"
                          />
                          <p>
                            {love_value?.toLocaleString() ?? 0}
                          </p>
                        </div>
                        <div className="flex items-center">
                          <img
                            src={emojiHaha}
                            alt="emojiHaha"
                            className="mr-1.5"
                          />
                          <p>
                            {haha_value?.toLocaleString() ?? 0}
                          </p>
                        </div>
                        <div className="flex items-center">
                          <img
                            src={angryEmoji}
                            alt="angryEmoji"
                            className="mr-1.5"
                          />
                          <p>
                            {angry_value?.toLocaleString() ??
                              0}
                          </p>
                        </div>
                        <div className="flex items-center">
                          <img
                            src={emojiWow}
                            alt="emojiWow"
                            className="mr-1.5"
                          />
                          <p>
                            {wow_value?.toLocaleString() ?? 0}
                          </p>
                        </div>
                        <div className="flex items-center">
                          <img
                            src={emojiSad}
                            alt="emojiSad"
                            className="mr-1.5"
                          />
                          <p>
                            {sad_value?.toLocaleString() ?? 0}
                          </p>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>

                <span className="title_1 flex items-center ml-auto">
                  {reaction_value?.toLocaleString() ?? 0}
                  {variation_posts_reactions > 0 ? (
                    <div
                      style={{
                        width: 15,
                        marginLeft: 15,
                        marginRight: 5,
                        fill: "green",
                      }}
                    >
                      <UpIcon />
                    </div>
                  ) : (
                    <div
                      style={{
                        width: 15,
                        marginLeft: 15,
                        marginRight: 5,
                        fill: "red",
                      }}
                    >
                      <DownIcon />
                    </div>
                  )}
                  {variation_posts_reactions.toFixed(2) || 0}%
                </span>
              </div>
              <hr className="mb-4 mt-1" />
            </div>
            <div className="text-xs">
              <div className="flex items-center">
                <span className=""></span>
                <div className="ml-2 flex capitalize">
                  {t("Performance_report.comments")}:
                </div>
                <span className="title_1 flex items-center ml-auto">
                  {comment_value?.toLocaleString() ?? 0}
                  {variation_posts_comments > 0 ? (
                    <div
                      style={{
                        width: 15,
                        marginLeft: 15,
                        marginRight: 5,
                        fill: "green",
                      }}
                    >
                      <UpIcon />
                    </div>
                  ) : (
                    <div
                      style={{
                        width: 15,
                        marginLeft: 15,
                        marginRight: 5,
                        fill: "red",
                      }}
                    >
                      <DownIcon />
                    </div>
                  )}
                  {variation_posts_comments.toFixed(2) || 0}%
                </span>
              </div>
              <hr className="mb-4 mt-1" />
            </div>
            <div className="text-xs">
              <div className="flex items-center">
                <span className=""></span>
                <div className="ml-2 flex capitalize">
                  {t("Performance_report.shares")} :
                </div>
                <span className="title_1 flex items-center ml-auto">
                  {share_value?.toLocaleString() ?? 0}
                  {variation_posts_shares > 0 ? (
                    <div
                      style={{
                        width: 15,
                        marginLeft: 15,
                        marginRight: 5,
                        fill: "green",
                      }}
                    >
                      <UpIcon />
                    </div>
                  ) : (
                    <div
                      style={{
                        width: 15,
                        marginLeft: 15,
                        marginRight: 5,
                        fill: "red",
                      }}
                    >
                      <DownIcon />
                    </div>
                  )}
                  {variation_posts_shares.toFixed(2) || 0}%
                </span>
              </div>
              <hr className="mb-4 mt-1" />
            </div>

            {socialType == "facebook" ? (
              <div className="text-xs font-bold">
                <div className="flex items-center">
                  <span className=""></span>
                  <div className="ml-2 flex capitalize">Total clics :</div>
                  <span className="title_1 flex items-center ml-auto">
                    {props.data.total.posts_clicks?.toLocaleString() ?? 0}
                    {variation_posts_clicks > 0 ? (
                      <div
                        style={{
                          width: 15,
                          marginLeft: 15,
                          marginRight: 5,
                          fill: "green",
                        }}
                      >
                        <UpIcon />
                      </div>
                    ) : (
                      <div
                        style={{
                          width: 15,
                          marginLeft: 15,
                          marginRight: 5,
                          fill: "red",
                        }}
                      >
                        <DownIcon />
                      </div>
                    )}
                    {variation_posts_clicks.toFixed(2) || 0}%
                  </span>
                </div>
                <hr className="mb-4 mt-1" />
              </div>
            ) : null}

            {socialType == "facebook" ? (
              <div className="text-xs">
                <div className="flex items-center">
                  <span className=""></span>
                  <div className="ml-2 flex">
                    {t("Performance_report.other_post_clicks")}:
                  </div>
                  <span className="title_1 flex items-center ml-auto">
                    {props.data.total.details_clicks.other_clicks?.toLocaleString() ||
                      0}
                    {variation_posts_other_clicks > 0 ? (
                      <div
                        style={{
                          width: 15,
                          marginLeft: 15,
                          marginRight: 5,
                          fill: "green",
                        }}
                      >
                        <UpIcon />
                      </div>
                    ) : (
                      <div
                        style={{
                          width: 15,
                          marginLeft: 15,
                          marginRight: 5,
                          fill: "red",
                        }}
                      >
                        <DownIcon />
                      </div>
                    )}
                    {variation_posts_other_clicks.toFixed(2) || 0}%
                  </span>
                </div>
                <hr className="mb-4 mt-1" />
              </div>
            ) : null}

            {socialType == "facebook" ? (
              <div className="text-xs">
                <div className="flex items-center">
                  <span className=""></span>
                  <div className="ml-2 flex ">
                    {t("Performance_report.clics_photos")} :
                  </div>
                  <span className="title_1 flex items-center ml-auto">
                    {props.data.total.details_clicks.photo_view?.toLocaleString() ??
                      0}
                    {variation_posts_photo_view > 0 ? (
                      <div
                        style={{
                          width: 15,
                          marginLeft: 15,
                          marginRight: 5,
                          fill: "green",
                        }}
                      >
                        <UpIcon />
                      </div>
                    ) : (
                      <div
                        style={{
                          width: 15,
                          marginLeft: 15,
                          marginRight: 5,
                          fill: "red",
                        }}
                      >
                        <DownIcon />
                      </div>
                    )}
                    {variation_posts_photo_view.toFixed(2) || 0}%
                  </span>
                </div>
                <hr className="mb-4 mt-1" />
              </div>
            ) : null}

            {socialType == "facebook" ? (
              <div className="text-xs">
                <div className="flex items-center">
                  <span className=""></span>
                  <div className="ml-2 flex">
                    {t("Performance_report.clics_links")} :
                  </div>
                  <span className="title_1 flex items-center ml-auto">
                    {props.data.total.details_clicks.link_clicks.toLocaleString() ??
                      0}
                    {variation_posts_link_clicks > 0 ? (
                      <div
                        style={{
                          width: 15,
                          marginLeft: 15,
                          marginRight: 5,
                          fill: "green",
                        }}
                      >
                        <UpIcon />
                      </div>
                    ) : (
                      <div
                        style={{
                          width: 15,
                          marginLeft: 15,
                          marginRight: 5,
                          fill: "red",
                        }}
                      >
                        <DownIcon />
                      </div>
                    )}
                    {variation_posts_link_clicks.toFixed(2) || 0}%
                  </span>
                </div>
                <hr className="mb-4 mt-1" />
              </div>
            ) : null}
          </div>
        </div>
      );
    }
  };

  return (
    <div>
      <div
        data-value={props.config.title}
        className="style_title_chart highchart_block1 mb-5"
      >
        <div className="flex items-center">
          <div className="content_style_title">
            <h1>{t("Performance_report.engagement")}</h1>
            <p>{t("Performance_report.engagement_desc")}</p>
          </div>

          <button
            data-html2canvas-ignore
            onClick={printChart}
            className="ml-auto mr-1.5 w-5 h-5 print_capture"
          ></button>
        </div>
        <div className="highchart_block flex">
          <div className="highchart_block1 full_width">
            <HighchartsReact
              highcharts={Highcharts}
              options={options}
              callback={highchartsCallback}
            />
          </div>

          <div className="highchart_block1 full_width">{renderTotal()}</div>

          {isNuageVisible && (
            <WordCloud
              wordArraAll={wordArraAll}
              page_id={page_id}
              start_date={start_date}
              end_date={end_date}
              isArabic={isArabic}
              printChart={printChart}
            />
          )}

        </div>
      </div>
    </div>
  );
};

export default PieEngagement;
