import React, { useRef, useState, useEffect } from "react";
import dayjs from "dayjs";
import API from "../../utils/Api";
import Pagination from "./Pagination";
import MediaSkeleton from "./Skeleton";
import { Link } from "react-router-dom";

import { Badge } from 'primereact/badge';
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Tooltip } from 'primereact/tooltip';
import { useTranslation } from "react-i18next";
import { InputText } from "primereact/inputtext";

const PostList = ({ state, posts, keys }) => {
    
    const toast = useRef(null);  
    const { t, i18n } = useTranslation();
    const [heightMsg, setHeightMsg] = useState(0);
    const [mediaHeight, setMediaHeight] = useState(0);
    const [visibleInputId, setVisibleInputId] = useState(null);

    const initialInputState = posts
        ? posts.reduce((acc, post) => {
            acc[post.id] = "";
            return acc;
          }, {})
        : {};

    const [inputValues, setInputValues] = useState(initialInputState);
    const [localPosts, setLocalPosts] = useState(posts);

    const current_page = state?.currentPage?.page;

    useEffect(() => {
        setLocalPosts(posts);
    }, [posts]);

    const calculateMaxHeight = () => {
        const mediaElements = document.querySelectorAll('.media_block');
        const contentElements = document.querySelectorAll('.content_block');

        let maxHeightMedia = 0;
        let maxHeightContent = 0;

        contentElements.forEach((content) => {
          const contentHeight = content.scrollHeight;
          if (contentHeight > maxHeightContent) {
            maxHeightContent = contentHeight;
          }
        });
    
        mediaElements.forEach((media) => {
          const mediaHeight = media.scrollHeight;
          if (mediaHeight > maxHeightMedia) {
            maxHeightMedia = mediaHeight;
          }
        });

        setHeightMsg(maxHeightContent);
        setMediaHeight(maxHeightMedia);
        
    };

    useEffect(() => {
        calculateMaxHeight();
        window.addEventListener('resize', calculateMaxHeight);
        return () => {
            window.removeEventListener('resize', calculateMaxHeight);
        };
    }, [localPosts]);

    const handleButtonClick = (postId) => {
        setVisibleInputId(postId);
    };

    const accept = async (postId) => {
        const value = inputValues[postId];

        if (!value) {
            const inputElement = document.getElementById(`input_${postId}`);
            inputElement.classList.add("border-[#e24c4c]");

            toast.current.show({
                severity: "error",
                summary: "Merci",
                detail: "D'ajouter un tag",
                life: 3000,
            });
        } else {
            setVisibleInputId(null);
            setInputValues((prevInputValues) => ({
                ...prevInputValues,
                [postId]: "",
            }));

            try {
                const response = await API.pages.tag_save(postId, value);
                toast.current.show({
                    severity: "success",
                    summary: "Tag",
                    detail: "Ajouté avec succès",
                    life: 3000,
                });
                console.log(response.data);
            } catch (error) {
                console.error(error);
            }
        }
    };

    const reject = (postId) => {
        setInputValues((prevInputValues) => ({
            ...prevInputValues,
            [postId]: "",
        }));
        setVisibleInputId(null);
    };

    const handleChange = (e, postId) => {
        const newValue = e.target.value;
        setInputValues((prevInputValues) => ({
            ...prevInputValues,
            [postId]: newValue,
        }));
    };

    const TexteLimite = ({ texte, longueurLimite }) => {
        if (!texte) return <span>Tag</span>;

        if (texte.length <= longueurLimite) {
            return <span>{texte}</span>;
        } else {
            const texteLimite = texte.slice(0, longueurLimite) + "...";
            return <span>{texteLimite}</span>;
        }
    };

    const changePostAttribute = (postId, attribute, value) => {
        setLocalPosts((prevPosts) =>
            prevPosts.map((post) =>
                post.id === postId ? { ...post, [attribute]: value } : post
            )
        );
    };

    const determinePostUrl = (post) => {
        if (post.is_real === 1 && post.url) {
            return `https://www.facebook.com/${post.url}`;
        } else if (post.type === "storie") {
            return `https://www.facebook.com/stories/${post.social_id}`;
        } else {
            return `https://www.facebook.com/${post.social_id}`;
        }
    };    

    //-- Score
    const score_color = {
        "A+": "#8ed55c",
        A: "#4a932b",
        B: "#f9cb33",
        C: "#f5c499",
        D: "#e5e5e5",
    };

    console.log('keys', keys);
    console.log('posts', localPosts);
    console.log('current_page', current_page);
    
    // PerformanceReport Post Image
    const PerformanceReport = (post) => {
        const status_type = post?.status_type ?? '';

        const data = JSON.parse(post.data);
        const views = data?.data?.total_video_views ?? 0;
        const lecture = post?.views ?? 0;

        const reach = (post?.reach ?? 0) + (post?.reach_ad ?? 0);

        const clicks = post?.clicks ?? 0;
        const saved = (post?.saved ?? 0) + (post?.saved_ad ?? 0);
        const comments = (post?.comments ?? 0) + (post?.comments_ad ?? 0);
        const total_reaction = (post?.haha ?? 0) + (post?.likes ?? 0) + (post?.likes_ad ?? 0) + (post?.love ?? 0) + 
                                (post?.none ?? 0) + (post?.sad ?? 0) + (post?.thankful ?? 0) + (post?.wow ?? 0) + (post?.angry ?? 0);
        
        const total_interaction = total_reaction + comments + saved + (post?.shares ?? 0);
        const total_engagement = total_interaction + clicks;

        return (
            <div className="grid gap-x-8 gap-y-4 grid-cols-3">
                <div className="border-b-2">
                    <span className="block text-[#101828] text-[14px] font-medium">{total_engagement}</span>
                    <span className="block text-[#475467] text-[14px] overflow-hidden text-ellipsis">{t('Performance_report.total_eng')}</span>
                </div>
                <div className="border-b-2">
                    <span className="block text-[#101828] text-[14px] font-medium">{reach}</span>
                    <span className="block text-[#475467] text-[14px] overflow-hidden text-ellipsis">{t('Partie_objectifs.Portée')}</span>
                </div>
                <div className="border-b-2">
                    <span className="block text-[#101828] text-[14px] font-medium">{total_reaction}</span>
                    <span className="block text-[#475467] text-[14px] overflow-hidden text-ellipsis">{t('Performance_report.reactions')}</span>                                                
                </div>
                <div className="border-b-2">
                    <span className="block text-[#101828] text-[14px] font-medium">{comments}</span>
                    <span className="block text-[#475467] text-[14px] overflow-hidden text-ellipsis">{t('Performance_report.comments')}</span>                                                
                </div>
                <div className="border-b-2">
                    <span className="block text-[#101828] text-[14px] font-medium">{post?.shares ?? 0}</span>
                    <span className="block text-[#475467] text-[14px] overflow-hidden text-ellipsis">{t('Performance_report.shares')}</span>  
                </div>
                <div className="border-b-2">
                    <span className="block text-[#101828] text-[14px] font-medium">{clicks}</span>
                    <span className="block text-[#475467] text-[14px] overflow-hidden text-ellipsis">{t('Performance_report.clics')}</span>  
                </div>
                {status_type == "added_video" && post.is_real != 1 &&
                <div className="border-b-2">
                    <span className="block text-[#101828] text-[14px] font-medium">{views}</span>
                    <span className="block text-[#475467] text-[14px] overflow-hidden text-ellipsis">{t('Performance_report.views')}</span>  
                </div>
                }
                {post.is_real == 1 &&
                <div className="border-b-2 relative">
                    <span className="block text-[#101828] text-[14px] font-medium">{lecture}</span>
                    <span className="block text-[#475467] text-[14px] overflow-hidden text-ellipsis">{t('Performance_report.lectures')}</span> 
                     
                     {/* info */}
                    <div className="absolute top-[-10px] right-0">
                        <Tooltip target=".custom-target-icon" />

                        <i className="custom-target-icon pi pi-info-circle p-text-secondary p-overlay-badge"
                            data-pr-tooltip="Nombre de lectures de votre reel, après la comptabilisation d’une impression. Cela comprend les lectures de 1 ms ou plus et inclut les relectures. Au cours d’une même session de reel, les lectures consécutives à la première sont comptées comme des relectures."
                            data-pr-position="right"
                            data-pr-at="right+5 top"
                            data-pr-my="left center-2"
                            style={{ fontSize: '12px', cursor: 'pointer' }}>
                        </i>
                    </div>
                </div>
                }
            </div>
        );
    }

    return (
        <div>
            {localPosts.length > 0 && (
                <div>
                    <div className="grid grid-cols-5 gap-5">
                        {localPosts && localPosts.length > 0 ? (
                            localPosts.map((post, index) => {
                                const post_url = determinePostUrl(post); // Declare post_url here
                
                                return (
                                    <div
                                        rel="noopener noreferrer"
                                        key={index}
                                        className="bg-white shadow rounded-[5px] p-4 mt-14"
                                    >
                                        <div className="flex flex-row justify-center mb-4">
                                            <a
                                                href={post_url} 
                                                target="_blank" 
                                                className="flex items-center"
                                            >
                                                <img
                                                    className="w-10 h-10 rounded-full"
                                                    src={`https://graph.facebook.com/${current_page.social_id}/picture`}
                                                    alt={`Image de profil de ${current_page.name}`}
                                                />
                                                <div className="ml-3">
                                                    <span className="font-medium">{current_page.name}</span>
                                                    <span className="block text-sm text-gray-500 font-light">
                                                        {dayjs(post.creation_time).format("ddd MMM DD YYYY")}
                                                    </span>
                                                </div>
                                            </a>
                
                                            <div className="flex items-center ml-auto btn_tag relative">
                                                <Toast ref={toast} />
                                                <Button
                                                    onClick={() => handleButtonClick(post.id)}
                                                    tooltip={post.tag}
                                                    tooltipOptions={{ position: "top" }}
                                                >
                                                    <TexteLimite texte={post.tag} longueurLimite={3} />
                                                </Button>
                                                {visibleInputId === post.id && (
                                                    <div className="absolute top-10 right-0 z-10 bg-white px-5 py-5 rounded-[5px] shadow">
                                                        <h2 className="text-center font-medium mb-2">
                                                            {t("Post_Library.add_tag")}
                                                        </h2>
                                                        <div
                                                            id={`input_${post.id}`}
                                                            className="border border-[#d0d5dd] rounded-[5px] mb-2"
                                                        >
                                                            <InputText
                                                                type="text"
                                                                onChange={(e) => handleChange(e, post.id)}
                                                                value={inputValues[post.id]}
                                                                placeholder={post.tag}
                                                                className="p-2"
                                                            />
                                                        </div>
                                                        <div className="footer_tag ml-auto table">
                                                            <button
                                                                className="bg-[#f9f5ff] px-4 py-2 text-[14px] text-[#6941c6] font-semibold rounded-[5px] mr-2"
                                                                onClick={() => accept(post.id)}
                                                            >
                                                                {t("Options.add")}
                                                            </button>
                                                            <button
                                                                className="bg-[#f9f5ff] px-4 py-2 text-[14px] text-[#6941c6] font-semibold rounded-[5px] reject_btn"
                                                                onClick={() => reject(post.id)}
                                                            >
                                                                {t("Options.cancel")}
                                                            </button>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <a
                                            href={post_url} 
                                            target="_blank" 
                                        >
                                            <div
                                                className="content_block py-5"
                                                style={{ height: heightMsg ? `${heightMsg}px` : "auto" }}
                                            >
                                                {post.message && (
                                                    <p className="text-asap-blue leading-snug md:leading-normal text-xs line-clamp-3">
                                                        {post.message}
                                                    </p>
                                                )}
                                            </div>
                                        </a>
                
                                        <a
                                            href={post_url} 
                                            target="_blank" 
                                            className="media_block bg-[#F2F4F7] flex items-center justify-center"
                                            style={{ height: mediaHeight ? `${mediaHeight}px` : "auto" }}
                                        >
                                            <MediaSkeleton
                                                post={post}
                                                key={`${post.id}-${post.status}`}
                                                onLoad={() => changePostAttribute(post.id, "status", "loaded")}
                                                onError={() => changePostAttribute(post.id, "status", "error")}
                                            />
                                        </a>

                                        <div className="data_post pb-2">

                                            <div className="flex items-center my-4">
                                                {current_page.social_type === "facebook" && post.type !== "storie" && (
                                                <span className="table px-4 py-1 rounded-[8px] bg-[#F4F3FF] text-[#5925DC] text-[14px] font-normal">
                                                    {post.reach_paid ? "Sponsorisé" : "Organic"}
                                                </span>
                                                )}
                                                {post.score_new && (
                                                <div
                                                    className="flex items-center justify-center ml-auto text-[12px] text-[#ffffff] rank_score rounded-full font-semibold w-[25px] h-[25px]"
                                                    style={{ backgroundColor: score_color[post.score_new] }}
                                                >
                                                    {post.score_new}
                                                </div>
                                                )}
                                            </div>
                                            {/* Appel à la fonction PerformanceReportImage */}
                                            {post.type != "storie" && PerformanceReport(post)}

                                            {post.comments + (post.comments_ad || 0) > 0 && (
                                                <div className="comment_moderation">
                                                <Link
                                                    to={`/reporting/${current_page.id}/post/${post.id}`}
                                                    state={{ keys: [], post }}
                                                >
                                                    {t("Post_Library.Comments_moderation")}
                                                </Link>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                );
                            })
                        ) : (
                            <p>No posts available</p>
                        )}
                    </div>   
                </div>            
            )}   
        </div>
    );
    
};

export default PostList;
