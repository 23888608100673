import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import TopVideoYTB from '../Reporting/YTB/TopVideoYTB'
import { AppContext, Loader } from "../../utils";
import Pagination from "./Pagination";
import API from "../../utils/Api";

import dayjs from "dayjs";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import dropdownIcon from "../../images/dropdown1.png";
import { ReactComponent as CalendarIcon } from "../../images/images/calendar.svg";
import { ReactComponent as CalendarArrow } from "../../images/images/calendarArrow.svg";

const PostScraping = (props) => {
    
    const { t } = useTranslation();
    const [status, setStatus] = useState("loading");
    const [state] = React.useContext(AppContext);

    const defaultDates = state.reportingPeriod || [
        dayjs().subtract(1, "year").toDate(),
        dayjs().toDate(),
    ];
    console.log('props =>', props?.reportingData);
    
    const [total, setTotal] = useState(1);
    const [posts, setPosts] = useState([]);
    const [marques, setMarques] = useState([]);
    const [per_page, setPerPage] = useState(5);
    const [dates, setDates] = useState(defaultDates);
    const [paginatePage, setPaginatePage] = useState(1);
    const [selectedType, setSelectedType] = useState(null);
    const [selectedFilter, setSelectedFilter] = useState(null);
    const [selectedMarque, setSelectedMarque] = useState(null);

    const marque = [
        { name: t('Benchmark.marque'), code: '' },
        ...marques.map(item => ({
            name: item.name,
            code: item.id
        }))
    ];
    const types = [
        { name: t('Post_Library.post_type_video'), code: '' },
        { name: t('Performance_report.videos'), code: 'video' },
        { name: t('Performance_report.shorts'), code: 'shorts' }
    ];
    const filtres = [
        {
            name: t("Benchmark.Sorting_Criteria"),
            code: "",
        },
        {
            name: t("Post_Library.Les plus récents"),
            code: "date",
            asc: false,
        },
        {
            name: t("Post_Library.Les plus engageants"),
            code: "engagement",
            asc: false,
        },
        {
            name: t("Post_Library.Les plus likés"),
            code: "likes",
            asc: false,
        },
        {
            name: t("Post_Library.top_viewed"),
            code: "views",
            asc: false,
        },
        {
            name: t("Post_Library.Les plus commentés"),
            code: "comments",
            asc: false,
        },
        {
            name: t("Post_Library.Les moins récents"),
            code: "date",
            asc: true,
        },
        {
            name: t("Post_Library.Les moins engageants"),
            code: "engagement",
            asc: true,
        },
        {
            name: t("Post_Library.Les moins likés"),
            code: "likes",
            asc: true,
        },
        {
            name: t("Post_Library.least_viewed"),
            code: "views",
            asc: true,
        },
        {
            name: t("Post_Library.Les moins commentés"),
            code: "comments",
            asc: true,
        },
    ];
    
    const formattedDates = dates.map(date => {
        const d = new Date(date);
        return d.toISOString().split('T')[0]; // Prend la partie "YYYY-MM-DD"
    });
    
    const date_start = formattedDates[0];
    const date_end = formattedDates[1];
    const loadPosts = async () => {
        try {
            setStatus("loading");
            const data = {
                paginate: paginatePage,
                selectedType: selectedType,
                selectedFilter: selectedFilter,
                selectedMarque: selectedMarque,
                date_start: date_start,
                date_end: date_end,
            };
            const response = await API.postscraping.getPostsScraping(data);
            setPosts(response?.data?.video_data?.data);
            setTotal(response?.data?.video_data?.total);
            setPerPage(response?.data?.video_data?.per_page);
            setMarques(response?.data?.marques);
            console.log('response:', response);
            setStatus("");
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        if (dates[0] && dates[1]) { 
          loadPosts();
        }
    }, [dates, paginatePage, selectedMarque, selectedType, selectedFilter]);

    const handlePageChange = (page) => {
      setPaginatePage(page);
    };

    console.log('marques', marques);
    

    if (status == "loading") return <Loader />;

    return (
        <div>  
            {/* calander */}
            <div className="flex items-center gap-6">
                <div className="flex bg-white border-gray-300  border  items-center rounded font-CityOfEmber   calendar-navbar w-72  ">
                    <label
                    htmlFor="date_range"
                    className="cursor-pointer pl-6 dark:text-asap-white text-asap-blue"
                    >
                    <CalendarIcon />
                    </label>
                    <Calendar
                        value={dates}
                        dateFormat="yy/mm/dd"
                        onChange={(e) => setDates(e.value)}
                        selectionMode="range"
                        readOnlyInput
                        panelClassName="mt-6 bg-asap-gray-900 p-2 font-CityOfEmber text-gray-900 font-bold"
                        className="font-CityOfEmber text-gray-900 font-bold "
                        inputClassName=" ml-2 cursor-pointer   outline-none  text-asap-blue text-sm text-gray-900 font-semibold "
                    />
                    <label
                    htmlFor="date_range"
                    className="cursor-pointer pl-2 cursor-pointer dark:text-asap-white text-asap-blue"
                    >
                    <CalendarArrow />
                    </label>
                </div>
                <div className="flex gap-2">
                    <span>{t("Performance_report.period")}</span>
                    {dates[0] && (
                    <div className="flex gap-2">
                        <span>{dates[0]?.toDateString()}</span> -
                        <span>{dates[1]?.toDateString()}</span>
                    </div>
                    )}
                </div>
            </div>            

            {/* selectbar */}
            <div className="flex items-center justify-between mt-8">
                <div className="flex items-center">
                    <h1 className="text-2xl float-left font-bold ml-4">
                        {t("Post_Library.post_library")}
                    </h1>
                    <span className="ml-5 bg-[#EAECF0] text-[12px] text-[#344054] font-semibold p-[5px] px-2 py-1 rounded-[16px]">
                        {total}
                    </span>
                </div>

                <div className=" float-right mr-8 flex gap-2">
                    <div className="flex items-center border bg-white rounded border-gray-300 h-10">
                        <img className="w-6 h-6 ml-4" src={dropdownIcon} alt="" />
                        <div>
                        <Dropdown 
                            value={selectedMarque} 
                            onChange={(e) => setSelectedMarque(e.value)} 
                            options={marque} 
                            optionLabel="name" 
                            placeholder={t('Benchmark.marque')} 
                            className=" float-right text-gray-800"
                        />
                        </div>
                    </div>
                    <div className="flex items-center border bg-white rounded border-gray-300 h-10">
                        <img className="w-6 h-6 ml-4" src={dropdownIcon} alt="" />
                        <div>
                        <Dropdown 
                            value={selectedType} 
                            onChange={(e) => setSelectedType(e.value)} 
                            options={types} 
                            optionLabel="name" 
                            placeholder={t('Post_Library.post_type_video')} 
                            className=" float-right text-gray-800"
                        />
                        </div>
                    </div>
                    <div className="flex items-center border bg-white rounded border-gray-300 h-10">
                        <img className="w-6 h-6 ml-4" src={dropdownIcon} alt="" />
                        <div>
                        <Dropdown 
                            value={selectedFilter} 
                            onChange={(e) => setSelectedFilter(e.value)} 
                            options={filtres} 
                            optionLabel="name"
                            placeholder={t('Benchmark.Sorting_Criteria')}  
                            className=" float-right text-gray-800"
                        />
                        </div>
                    </div>
                </div>
            </div>
            
            <TopVideoYTB posts={posts} />

            {total > per_page && (
            <div className="flex justify-center py-5 mt-5 ml-8 border border-[#eaecf0] rounded-[12px] bg-white">
            <Pagination
                onPageChange={handlePageChange}
                totalCount={total}
                currentPage={paginatePage}
                pageSize={per_page} 
                className="custom-pagination-class"
            />
            </div>
            )}
        </div>
    );
    
};

export default PostScraping;
