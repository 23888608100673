import React from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as FansIcon } from "../../../images/svg/icon_total_fans.svg";

const ReportingYTB = (props) => {
 
  const { t } = useTranslation();
  
  const data = props?.reportingData?.ytb  ?? {};
  const followers = data?.insights?.followers.toLocaleString();
  const views = data?.type?.total?.views.toLocaleString();
  const video_count = data?.type?.total?.count.toLocaleString();
  const active_date = data?.insights?.active_date;
   
  return (
    <div className=" mt-8 flex gap-6 justify-center text-gray-700 text-sm 2xl:text-base">
      
      <div className="bg-white px-4 py-5 rounded-[8px] w-full border border-[#eaecf0]">
        <div className="flex items-center">
          <div className="icon_cards p-3 shadow-[0_1px_2px_0_rgba(16,24,40,0.05)] border border-[#eaecf0] rounded-[8px]">
            <FansIcon />
          </div>
          <h2 className="text-[#101828] text-[16px] font-medium ml-4">{t("Performance_report.Total_fans")}</h2>
        </div>
        <h3 className="font-semibold text-[#101828] text-[30px] mt-4">{followers}</h3>
      </div>
      
      <div className="bg-white px-4 py-5 rounded-[8px] w-full border border-[#eaecf0]">
        <div className="flex items-center">
          <div className="icon_cards p-3 shadow-[0_1px_2px_0_rgba(16,24,40,0.05)] border border-[#eaecf0] rounded-[8px]">
            <FansIcon />
          </div>
          <h2 className="text-[#101828] text-[16px] font-medium ml-4">{t("Performance_report.views")}</h2>
        </div>
        <h3 className="font-semibold text-[#101828] text-[30px] mt-4">{views}</h3>
      </div>
      
      <div className="bg-white px-4 py-5 rounded-[8px] w-full border border-[#eaecf0]">
        <div className="flex items-center">
          <div className="icon_cards p-3 shadow-[0_1px_2px_0_rgba(16,24,40,0.05)] border border-[#eaecf0] rounded-[8px]">
            <FansIcon />
          </div>
          <h2 className="text-[#101828] text-[16px] font-medium ml-4">{t("Performance_report.total_videos")}</h2>
        </div>
        <h3 className="font-semibold text-[#101828] text-[30px] mt-4">{video_count}</h3>
      </div>
      
      <div className="bg-white px-4 py-5 rounded-[8px] w-full border border-[#eaecf0]">
        <div className="flex items-center">
          <div className="icon_cards p-3 shadow-[0_1px_2px_0_rgba(16,24,40,0.05)] border border-[#eaecf0] rounded-[8px]">
            <FansIcon />
          </div>
          <h2 className="text-[#101828] text-[16px] font-medium ml-4">{t("Performance_report.active_date")}</h2>
        </div>
        <h3 className="font-semibold text-[#101828] text-[30px] mt-4">{active_date}</h3>
      </div>

    </div>
  );
};

export default ReportingYTB;
