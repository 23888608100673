import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import MediaSkeleton from "../Skeleton";
import { Tooltip } from 'primereact/tooltip';
import { useTranslation } from "react-i18next";
import videoIcon from "../../../images/svg/video_ytb_border.svg";
import shotsIcon from "../../../images/svg/shorts_ytb_border.svg";

const TopVideoYTB = ({ posts }) => {
    
    const { t } = useTranslation();
    const [heightMsg, setHeightMsg] = useState(0);
    const [mediaHeight, setMediaHeight] = useState(0);

    const [localPosts, setLocalPosts] = useState(posts);

    const calculateMaxHeight = () => {
        const mediaElements = document.querySelectorAll('.media_block');
        const contentElements = document.querySelectorAll('.content_block');

        let maxHeightMedia = 0;
        let maxHeightContent = 0;

        contentElements.forEach((content) => {
          const contentHeight = content.scrollHeight;
          if (contentHeight > maxHeightContent) {
            maxHeightContent = contentHeight;
          }
        });
    
        mediaElements.forEach((media) => {
          const mediaHeight = media.scrollHeight;
          if (mediaHeight > maxHeightMedia) {
            maxHeightMedia = mediaHeight;
          }
        });

        setHeightMsg(maxHeightContent);
        setMediaHeight(maxHeightMedia);
        
    };

    const changePostAttribute = (postId, attribute, value) => {
        setLocalPosts((prevPosts) =>
            prevPosts.map((post) =>
                post.id === postId ? { ...post, [attribute]: value } : post
            )
        );
    };

    useEffect(() => {
        calculateMaxHeight();
        window.addEventListener('resize', calculateMaxHeight);
        return () => {
            window.removeEventListener('resize', calculateMaxHeight);
        };
    }, [localPosts]);
    
    const formatDuration = (duration) => {
        // Si le format est "mm:ss" (par exemple, 3:30)
        if (typeof duration === "string" && duration.includes(":")) {
          const [minutes, seconds] = duration.split(":").map(Number);
          return `${minutes}:${seconds}`;
        }
      
        // Si le format est en secondes (par exemple, 76)
        if (typeof duration === "number" || !isNaN(duration)) {
          const totalSeconds = parseInt(duration, 10);
          const minutes = Math.floor(totalSeconds / 60);
          const seconds = totalSeconds % 60;
          return `${minutes}:${seconds}`;
        }
      
        return "Invalid duration";
    };
    
    // PerformanceReport Post Image
    const PerformanceReport = (post) => {

        const likes = post?.likes ?? 0;
        const views = post?.views ?? 0;
        const type_video = post?.type_video ?? 0;
        const comment_count = post?.comment_count ?? 0;
        const duration = formatDuration(post?.duration ?? 0);
        const total_engagement = Number(likes) + Number(views) + Number(comment_count);        

        return (
            <div className="grid gap-x-8 gap-y-4 grid-cols-3 pt-5">
                <div className="border-b-2">
                    <span className="block text-[#101828] text-[14px] font-medium">{total_engagement}</span>
                    <span className="block text-[#475467] text-[14px] overflow-hidden text-ellipsis">{t('Performance_report.total_eng')}</span>
                </div>
                <div className="border-b-2">
                    <span className="block text-[#101828] text-[14px] font-medium">{type_video}</span>
                    <span className="block text-[#475467] text-[14px] overflow-hidden text-ellipsis">{t('Performance_report.video_type')}</span>
                </div>
                <div className="border-b-2">
                    <span className="block text-[#101828] text-[14px] font-medium">{likes}</span>
                    <span className="block text-[#475467] text-[14px] overflow-hidden text-ellipsis">{t('Performance_report.likes')}</span>  
                </div>
                <div className="border-b-2">
                    <span className="block text-[#101828] text-[14px] font-medium">{views}</span>
                    <span className="block text-[#475467] text-[14px] overflow-hidden text-ellipsis">{t('Performance_report.views')}</span>                                                
                </div>
                <div className="border-b-2">
                    <span className="block text-[#101828] text-[14px] font-medium">{comment_count}</span>
                    <span className="block text-[#475467] text-[14px] overflow-hidden text-ellipsis">{t('Performance_report.comments')}</span>                                                
                </div>
                <div className="border-b-2">
                    <span className="block text-[#101828] text-[14px] font-medium">{duration}</span>
                    <span className="block text-[#475467] text-[14px] overflow-hidden text-ellipsis">{t('Performance_report.video_duration')}</span>  
                </div>
            </div>
        );
    }
    
    return (
        <div>
            {localPosts.length > 0 && (
                <div>
                    <div className="grid grid-cols-5 gap-5">
                        {localPosts && localPosts.length > 0 ? (
                            localPosts.map((post, index) => {
                
                                return (
                                    <div
                                        rel="noopener noreferrer"
                                        key={index}
                                        className="bg-white shadow rounded-[5px] p-4 mt-14"
                                    >
                                        <div className="flex flex-row mb-4">
                                            <a
                                                href={post.link} 
                                                target="_blank" 
                                                className="flex items-center"
                                            >
                                                <img
                                                    className="w-10 h-10 rounded-full"
                                                    src={post.picture}
                                                    alt={`Image de profil de ${post.name}`}
                                                />
                                                <div className="ml-3">
                                                    <span className="font-medium">{post.name}</span>
                                                    <span className="block text-sm text-gray-500 font-light">
                                                        {dayjs(post.creation_time).format("ddd MMM DD YYYY")}
                                                    </span>
                                                </div>
                                            </a>
                                        </div>
                                        <a
                                            href={post.link} 
                                            target="_blank" 
                                        >
                                            <div
                                                className="content_block py-5"
                                                style={{ height: heightMsg ? `${heightMsg}px` : "auto" }}
                                            >
                                                {post.titre && (
                                                    <h3 className="text-[12px] font-semibold mb-4">
                                                        {post.titre}
                                                    </h3>
                                                )}
                                                {post.description && (
                                                    <p className="text-[#5a6872] text-[12px]">
                                                        {post.description}
                                                    </p>
                                                )}
                                            </div>
                                        </a>
                                        
                                        <a
                                            href={post.link} 
                                            target="_blank" 
                                            className="media_block bg-[#F2F4F7] flex items-center justify-center"
                                            style={{ height: mediaHeight ? `${mediaHeight}px` : "auto" }}
                                        >
                                            <MediaSkeleton
                                                post={post}
                                                key={`${post.id}-${post.status}`}
                                                onLoad={() => changePostAttribute(post.id, "status", "loaded")}
                                                onError={() => changePostAttribute(post.id, "status", "error")}
                                            />
                                        </a>

                                        <div className="data_post pb-2">
                                            {/* Appel à la fonction PerformanceReportImage */}
                                            {PerformanceReport(post)}
                                        </div>
                                    </div>
                                );
                            })
                        ) : (
                            <p>No posts available</p>
                        )}
                    </div>   
                </div>            
            )}   
        </div>
    );
    
};

export default TopVideoYTB;
