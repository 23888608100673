import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { useTranslation } from "react-i18next";
import { Column } from 'primereact/column';

import { ReactComponent as UpIcon } from "../../../images/upIcon.svg";
import { ReactComponent as DownIcon } from "../../../images/downIcon.svg";

const ChiffresKeysYtb = (props) => {

    const { t } = useTranslation();

    const [header, setHeader] = useState([]);
    const [getData, setData] = useState([]);

    const data = props?.state?.reportingData?.ytb;

    const benchmark_video = data?.benchmark?.bench_video_ytb;
    const insight_date_bench = data?.benchmark?.insight_date_bench;

    //-- manager page
    const manager_followers = data?.insights?.followers;
    const manager_video_count = data?.type?.total?.count;   
    const manager_publication = data?.type?.total;
    
    const manager_engagement = Number(manager_publication?.comments || 0) + Number(manager_publication?.likes || 0) + Number(manager_publication?.views || 0);
    const manager_taux_engagement = manager_followers ? ((manager_engagement) / manager_followers) * 100 : 0;


    const manager_views = manager_publication?.views;
    const manager_likes = manager_publication?.likes;
    const manager_comments = manager_publication?.comments;
    
    // Parcourir chaque élément de video
    benchmark_video.forEach(video => {
        const activeData = Object.values(insight_date_bench).find(item => item.page_id === video.page_id);
        
        if (activeData) {
            video.active_date = activeData.active_date;
            video.username = activeData.username;
            video.followers = activeData.followers;
        } else {
            video.active_date = 'NaN';
            video.username = 'NaN';
            video.followers = '0';
        }
    });

    // Calculer le total d'engagement pour chaque entrée
    const totalEngagement = benchmark_video.reduce((acc, item) => {
        return acc + 
               Number(item.likes_video || 0) + 
               Number(item.likes_shorts || 0) + 
               Number(item.views_shorts || 0) + 
               Number(item.views_video || 0) + 
               Number(item.comments_video || 0) + 
               Number(item.comments_shorts || 0);
    }, 0); 
    
    const totalReactions = benchmark_video.reduce((acc, item) => {
        return acc + 
        Number(item.likes_video || 0) + 
        Number(item.likes_shorts || 0) + 
        Number(item.comments_video || 0) + 
        Number(item.comments_shorts || 0) +
        Number(item.views_shorts || 0) + 
        Number(item.views_video || 0);
    }, 0); 

    const totalFollowers = benchmark_video.reduce((sum, item) => {
        const followers = Number(item.followers);
        if (!isNaN(followers)) {
            return sum + followers;
        }
        return sum;  // Ignore les valeurs non valides
    }, 0);
    const followers = totalFollowers / benchmark_video.length;

    
    const TotalTauxEngagement = totalFollowers ? (totalReactions / totalFollowers) * 100 : 0; 

    const TotalVideoCount = benchmark_video.reduce((sum, item) => {
        const video_count = Number(item?.video || 0) + Number(item?.shorts || 0);
        
        if (!isNaN(video_count)) {
            return sum + video_count;
        }
        return sum;  // Ignore les valeurs non valides
    }, 0);
    const video_count = TotalVideoCount / benchmark_video.length;

    const TotalViewsVideo = benchmark_video.reduce((sum, item) => sum + Number(item.views_video), 0);
    const TotalViewsShorts = benchmark_video.reduce((sum, item) => sum + Number(item.views_shorts), 0);
    const views = (TotalViewsVideo + TotalViewsShorts) / benchmark_video.length;    

    const TotalLikesVideo = benchmark_video.reduce((sum, item) => sum + Number(item.likes_video), 0);
    const TotalLikesShorts = benchmark_video.reduce((sum, item) => sum + Number(item.likes_shorts), 0);
    const likes = (TotalLikesVideo + TotalLikesShorts) / benchmark_video.length;

    const TotalCommentVideo = benchmark_video.reduce((sum, item) => sum + Number(item.comments_video), 0);
    const TotalCommentShorts = benchmark_video.reduce((sum, item) => sum + Number(item.comments_shorts), 0);
    const comments = (TotalCommentVideo + TotalCommentShorts) / benchmark_video.length;

    const engagement = totalEngagement / benchmark_video.length;
    const tauxEngagement = TotalTauxEngagement / benchmark_video.length;

    function campareTwoValues(val1, val2) {
        if (val1 >= val2) {
          return <div className="w-4 m-auto fill-[#00FF00]"><UpIcon /></div>;
        } else {
          return <div className="w-4 m-auto fill-[#ff0000]"><DownIcon /></div>;
        }
    }

    const chiffre_key = [
        {
            name : t("Benchmark.Average_Total_Number_Fans"),
            marque: manager_followers,
            status: campareTwoValues(manager_followers, followers),
            moy_sect: followers.toLocaleString()
        },
        {
            name : t("Benchmark.Average_Number_Posts"),
            marque: manager_video_count,
            status: campareTwoValues(manager_video_count, video_count),
            moy_sect: video_count.toLocaleString()
        },
        {
            name : t("Benchmark.Average_Page_Engagement"),
            marque: manager_engagement.toLocaleString(),
            status: campareTwoValues(manager_engagement, engagement),
            moy_sect: engagement.toLocaleString()
        },
        {
            name : t("Benchmark.Average_Page_Engagement_Rate"),
            marque: manager_taux_engagement.toFixed(2),
            status: campareTwoValues(manager_taux_engagement, tauxEngagement),
            moy_sect: tauxEngagement.toFixed(2)
        },
        {
            name : t("Benchmark.Average_Number_Views_Page"),
            marque: manager_views,
            status: campareTwoValues(manager_views, views),
            moy_sect: views.toLocaleString()
        },
        {
            name : t("Benchmark.Average_Number_Likes_Page"),
            marque: manager_likes,
            status: campareTwoValues(manager_likes, likes),
            moy_sect: likes.toLocaleString()
        },
        {
            name : t("Benchmark.Average_Number_Comments_Page"),
            marque: manager_comments,
            status: campareTwoValues(manager_comments, comments),
            moy_sect: comments.toLocaleString()
        },
    ]


    // Colonnes dynamiques
    const dynamicColumns = [
        { field: "chiffres_key", name: "" },
        { field: "our_brand", name: t("Benchmark.our_brand") },
        { field: "comparer", name: "" },
        { field: "Sector_Averages", name: t("Benchmark.Sector_Averages") },
    ];
    
    // Préparer les données pour le tableau
    const staticData = chiffre_key.map(item => ({
        chiffres_key: item.name,
        our_brand: item.marque,
        comparer: item.status,  
        Sector_Averages: item.moy_sect,
    }));

    useEffect(() => {
        setData(staticData);
        setHeader(dynamicColumns);
    }, []);

    return (
        <div className="table-header mt-8 p-5 bg-white rounded-[8px]">
            <div className="flex items-center w-full mb-4">
                <h2 className="text-[18px] font-semibold">{t("Benchmark.Key_Metrics")}</h2>
            </div>
            <DataTable value={getData}>
                {header.map((col, index) => (
                    <Column
                        key={index}
                        field={col.field}
                        header={col.name}
                        className={index > 0 ? "center_header" : ""}
                        bodyStyle={index > 0 ? { textAlign: "center" } : {}}
                    ></Column>
                ))}
            </DataTable>

        </div>
    );
};

export default ChiffresKeysYtb;
