import React from "react";
import Header from "./Header";
import ReportingYTB from "./ReportingYTB";
import ReportingOverview from "./ReportingOverview";
import GoalsOverview from "./GoalsOverview";
import { Layout as DataLayout } from "../../DataCmp/Layout";

import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

const PerformanceReport = (props) => {
  
   //fix  full screen resizing
   setTimeout(() => window.dispatchEvent(new Event("resize")), 500);

  //console.log("reportingdata", props.reportingData);
  return (
    <div>
      <div>
        <Header />
      </div>
      <div className="content_reporting">
      {props?.reportingData?.socialType === 'ytb' ? (
        <ReportingYTB {...props} />
      ) : (
        <ReportingOverview {...props} />
      )}
        <GoalsOverview {...props} />

        <div className="dataLayout my-6">
          <DataLayout
            layout={props.reportingData.layouts.performance}
            data={props.reportingData}
          />
        </div>
      </div>
    </div>
  );
};

export default PerformanceReport;
