import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { API } from "../../utils";
import { Chips } from 'primereact/chips';
import { TabView, TabPanel } from "primereact/tabview";
import { OverlayPanel } from 'primereact/overlaypanel';
import { ProgressSpinner } from 'primereact/progressspinner';

const WordCloud = ({ page_id, start_date, end_date, isArabic, printChart }) => {

    const op = useRef(null);
    const [wordsData, setWordsData] = useState([]);
    const [ignoreList, setIgnoreList] = useState([]);
    const [wordRemove, setWordRemve] = useState('');
    const [getNewList, isNewList] = useState(false);
    
    const fetchData = async () => {
        try {
            const response = await API.pages.getTendanceWordApi(page_id, start_date, end_date, wordRemove, ignoreList);
            console.log('data ==>', response.data);
            setWordsData(response?.data?.data);
            setIgnoreList(response?.data?.ignoreList);
        } catch (error) {
            console.error(error);
        }
    };
    
    useEffect(() => {
        fetchData();
    }, [wordRemove]);
    
    const handleRemoveWord = (tabIndex, wordIndex) => {
        setWordsData((prev) =>
            prev.map((tab, i) =>
                i === tabIndex
                    ? {
                        ...tab,
                        nauge: tab.nauge.filter((_, index) => {
                            if (index === wordIndex) {
                                const removedWord = tab.nauge[index].word;
                                console.log(`Suppression du mot : ${removedWord}`);
                                
                                // Met à jour ignoreList immédiatement
                                setIgnoreList((prevIgnoreList) => {
                                    const updatedList = [...prevIgnoreList, removedWord];
                                    console.log('ignoreList updated:', updatedList);
                                    return updatedList;
                                });
                                
                                // Met à jour wordRemove (si nécessaire)
                                setWordRemve(removedWord);
                            }
                            return index !== wordIndex;
                        }),
                    }
                    : tab
            )
        );
    };    
    
    // Fonction pour gérer la fermeture de l'OverlayPanel
    const handleCloseOverlay = () => {
        console.log('OverlayPanel fermé');
        // Vous pouvez ajouter ici toute logique que vous souhaitez exécuter après la fermeture
    }; 

    console.log('ignoreList', ignoreList);
    const handleIgnoreListChange = (newList) => {
        setIgnoreList(newList);
    };   
    
    
    return (
        <div
            data-value="Tendances des mots clés"
            className={`nuage_word show_nauge full_width`}
        >
        <div className="wordCloud box_post border border-[#eaecf0] rounded-[12px] py-5 bg-white mt-5 p-4 mr-8 relative min-h-[470px] relative">

            <div className="flex items-center">
                {/* <button 
                    onClick={(e) => op.current.toggle(e)}
                    className="border bg-white rounded border-gray-300 table text-[#344054] text-[14px] font-semibold px-3 py-1"
                >Exclure un mot</button> */}
                <OverlayPanel ref={op} onHide={handleCloseOverlay}>
                    <Chips 
                        value={ignoreList} 
                        onChange={(e) => handleIgnoreListChange(e.value)} 
                        className="rounded border border-gray-300 table text-[#344054] text-[14px] font-semibold" 
                    />
                </OverlayPanel>

                <button
                data-html2canvas-ignore
                onClick={printChart}
                className="ml-auto mr-1.5 w-5 h-5 print_capture"
                ></button>
            </div>
            
            {wordsData.length == 0 && 
            <div className="absolute top-0 left-0 right-0 flex items-center bottom-0 z-10">
                <ProgressSpinner aria-label="Loading" style={{width: '50px', height: '50px'}} strokeWidth="5" animationDuration=".5s" />
            </div>
            }

            <TabView>
                {Array.isArray(wordsData) && wordsData.map((item, tabIndex) => (
                    <TabPanel
                        className="flex flex-wrap justify-center"
                        key={tabIndex}
                        header={item.name}
                    >
                        {item.nauge.map((wordObj, wordIndex) => {
                        const number_element = Math.floor(Math.random() * 5);

                        return (
                            <div
                            key={wordIndex}
                            className={`relative flex items-end mr-2 mb-2 group ${
                                isArabic(wordObj.word) ? "word_arabic flex" : ""
                            } element_${number_element}`}
                            >
                            <Link
                                to={`/reporting/${page_id}/tendance/${wordObj.word}`}
                                state={{
                                word: wordObj.word,
                                page_id: page_id,
                                start_date: start_date,
                                end_date: end_date,
                                }}
                                className="relative"
                            >
                                <span>{wordObj.word}</span>
                            </Link>

                            {/* Bouton Remove */}
                            <button
                                onClick={() => handleRemoveWord(tabIndex, wordIndex)}
                                className="absolute top-0 right-0 bg-red-500 text-white text-xs px-1 py-0.5 rounded opacity-0 group-hover:opacity-100 transition-opacity duration-200"
                            >
                                Remove
                            </button>
                            </div>
                        );
                        })}
                    </TabPanel>
                ))}

            </TabView>
        </div>
        </div>
    );
};

export default WordCloud;
