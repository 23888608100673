import React from "react";
import { useTranslation } from "react-i18next";
import Highcharts from "highcharts";
import html2canvas from "html2canvas";
import HighchartsReact from "highcharts-react-official";

import { ReactComponent as UpIcon } from "../../../images/upIcon.svg";
import { ReactComponent as DownIcon } from "../../../images/downIcon.svg";

const TypesPublicationYtb = (props) => {
    
  const { t } = useTranslation();

  const printChart = (event) => {
    event.preventDefault();

    let target = event.target;
    let parent = target.parentElement.parentElement;
    let name = parent.getAttribute("data-value");
    parent.classList.add("capture_style");

    html2canvas(parent, {
      onclone: (document) => {},
    }).then((canvas) => {
      const data = canvas.toDataURL("image/jpg");
      const link = document.createElement("a");

      if (typeof link.download === "string") {
        link.href = data;
        link.download = name + ".jpg";

        parent.classList.remove("capture_style");

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        window.open(data);
      }
    });
  };

  const previous_end_date = props.state.data.date_info.previous_end_day ?? 0;
  const previous_start_date = props.state.data.date_info.previous_start_day ?? 0;

  const COLORS = ["#596ae9", "#6955e6", "#22c4ac", "red", "orange"];

  let options = {
    credits: false,
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: "pie",
      width: 600,
      style: {
        fontFamily: "Inter",
      },
    },
    colors: COLORS,
    title: {
      text: "",
    },
    tooltip: {
      pointFormat: "<b>{point.percentage:.2f}%</b><br/>{point.y}",
    },
    accessibility: {
      point: {
        valueSuffix: "%",
      },
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: true,
          format: "<b>{point.name}</b>: {point.percentage:.2f} %",
        },
        size: "70%",
      },
    },
    series: [
      {
        innerSize: "50%",
        colorByPoint: true,
        animation: {
          duration: 2000,
        },
        data: [],
      },
    ],
  };

  const data = props?.state?.data?.ytb;
  
  const video = data?.type?.video?.count.toLocaleString();
  const shorts = data?.type?.shorts?.count.toLocaleString();

  const video_count = data?.type?.total?.count.toLocaleString();
  const views_count = data?.type?.total?.views;
  const likes_count = data?.type?.total?.likes;
  const comment_count = data?.type?.total?.comments;  

  const variation_video_count = data?.type?.total?.variation?.count;  
  const variation_video = data?.type?.video?.variation?.count;  
  const variation_shorts = data?.type?.shorts?.variation?.count;  
  const variation_views = data?.type?.total?.variation?.views;  
  const variation_likes = data?.type?.total?.variation?.likes;  
  const variation_comments = data?.type?.total?.variation?.comments;   

  const type_publication = [
    { name: 'Videos', y: +video },
    { name: 'Shorts', y: +shorts },
  ];
          
  // Correctly add the data to the series
  options.series[0].data.push(...type_publication);

  return (
    <div>
      <div data-value={t("Performance_report.title_publication")} className="style_title_chart bg-white">
        <div data-html2canvas-ignore className="flex items-center px-3 py-3">    
          <button
            onClick={printChart}
            className="mx-5 w-5 h-5 print_capture ml-auto"
          ></button>
        </div>
        
        <div className="mx-5 mb-5">
          <h1>{t("Performance_report.title_publication")}</h1>
          <p>{t("Performance_report.ytb_type_desc")}</p>
        </div>          
        
        <div className="grid grid-cols-2 gap-4 p-5 rounded-[8px] mb-5 bg-white">

          <div className="highchart_block1">
              <HighchartsReact highcharts={Highcharts} options={options} />
              <div className="capture_style"></div>
          </div>
          <div className="static_chart flex flex-col justify-center">

            <div className="bg-rectangle rounded-[8px] mb-10 relative mb-5 p-5">
                <h2 className="text-[#152935] text-[14px] font-bold">{t("Performance_report.total_videos")}</h2>
                <span className="block text-[#152935] text-[24px] font-extrabold mt-5">{video_count}</span>

                <div className="flex items-center mt-5">
                    <span className="block text-[#152935] text-[14px] font-extrabold mr-7">{variation_video_count} %</span>
                    <span className="block text-[#152935] text-[14px] font-extrabold">
                        {t("Options.compared")} {previous_end_date} - {previous_start_date}
                    </span>
                </div>
            </div>

            <div className="flex flex-col justify-center">
                <div class="flex items-center flex-row py-2 border-b-[1px]">
                    <div class="basis-1/2 flex items-center">
                        <div className="w-3 h-3 rounded-full bg-[#5596e6] mr-1.5"></div>
                        <span className="text-[#344563] text-[14px] font-semibold">{t('Performance_report.videos')}</span>
                    </div>
                    <div class="basis-1/5 text-center text-[#344563] text-[14px] font-semibold">{video.toLocaleString()}</div>
                    <div class="basis-1/5 text-center text-[#344563] text-[14px] font-semibold">
                        {variation_video > 0 ? (
                            <div className="w-4 m-auto fill-[#00FF00]">
                                <UpIcon />
                            </div>
                            ) : (
                            <div className="w-4 m-auto fill-[#ff0000]">
                                <DownIcon />
                            </div>
                        )}
                    </div>
                    <div class="basis-1/5 text-center text-[#344563] text-[14px] font-semibold">{variation_video}%</div>
                </div>
                <div class="flex items-center flex-row py-2 border-b-[1px]">
                    <div class="basis-1/2 flex items-center">
                    <div className="w-3 h-3 rounded-full bg-[#6955e6] mr-1.5"></div>
                        <span className="text-[#344563] text-[14px] font-semibold">{t('Performance_report.shorts')}</span>
                    </div>
                    <div class="basis-1/5 text-center text-[#344563] text-[14px] font-semibold">{shorts.toLocaleString()}</div>
                    <div class="basis-1/5 text-center text-[#344563] text-[14px] font-semibold">
                        {variation_shorts > 0 ? (
                            <div className="w-4 m-auto fill-[#00FF00]">
                                <UpIcon />
                            </div>
                            ) : (
                            <div className="w-4 m-auto fill-[#ff0000]">
                                <DownIcon />
                            </div>
                        )}
                    </div>
                    <div class="basis-1/5 text-center text-[#344563] text-[14px] font-semibold">{variation_shorts}%</div>
                </div>
                <div class="flex items-center flex-row py-2 border-b-[1px]">
                    <div class="basis-1/2 flex items-center">
                    <div className="w-3 h-3 rounded-full bg-[#22c4ac] mr-1.5"></div>
                        <span className="text-[#344563] text-[14px] font-semibold">{t('Performance_report.total_views')}</span>
                    </div>
                    <div class="basis-1/5 text-center text-[#344563] text-[14px] font-semibold">{views_count.toLocaleString()}</div>
                    <div class="basis-1/5 text-center text-[#344563] text-[14px] font-semibold">
                        {variation_views > 0 ? (
                            <div className="w-4 m-auto fill-[#00FF00]">
                                <UpIcon />
                            </div>
                            ) : (
                            <div className="w-4 m-auto fill-[#ff0000]">
                                <DownIcon />
                            </div>
                        )}
                    </div>
                    <div class="basis-1/5 text-center text-[#344563] text-[14px] font-semibold">{variation_views}%</div>
                </div>
                <div class="flex flex-row py-2 border-b-[1px]">
                    <div class="basis-1/2 flex items-center">
                        <div className="w-3 h-3 rounded-full bg-[#f1c658] mr-1.5 circle_title"></div>
                        <span className="text-[#344563] text-[14px] font-semibold">{t('Performance_report.total_likes')}</span>
                    </div>
                    <div class="basis-1/5 text-center text-[#344563] text-[14px] font-semibold">{likes_count.toLocaleString()}</div>
                    <div class="basis-1/5 text-center text-[#344563] text-[14px] font-semibold">
                        {variation_likes > 0 ? (
                            <div className="w-4 m-auto fill-[#00FF00]">
                                <UpIcon />
                            </div>
                            ) : (
                            <div className="w-4 m-auto fill-[#ff0000]">
                                <DownIcon />
                            </div>
                        )}
                    </div>
                    <div class="basis-1/5 text-center text-[#344563] text-[14px] font-semibold">{variation_likes}%</div>
                </div>
                <div class="flex flex-row py-2">
                    <div class="basis-1/2 flex items-center">
                        <div className="w-3 h-3 rounded-full bg-[#f6d991] mr-1.5 circle_title"></div>
                        <span className="text-[#344563] text-[14px] font-semibold">{t('Performance_report.total_comments')}</span>
                    </div>
                    <div class="basis-1/5 text-center text-[#344563] text-[14px] font-semibold">{comment_count.toLocaleString()}</div>
                    <div class="basis-1/5 text-center text-[#344563] text-[14px] font-semibold">
                        {variation_comments > 0 ? (
                            <div className="w-4 m-auto fill-[#00FF00]">
                                <UpIcon />
                            </div>
                            ) : (
                            <div className="w-4 m-auto fill-[#ff0000]">
                                <DownIcon />
                            </div>
                        )}
                    </div>
                    <div class="basis-1/5 text-center text-[#344563] text-[14px] font-semibold">{variation_comments}%</div>
                </div>

            </div>
          </div>

        </div>

      </div>
    </div>
  );
};

export default TypesPublicationYtb;
