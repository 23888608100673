import React from "react";
import { useTranslation } from "react-i18next";
import { AppContext, API, useUtils } from "../../utils";
import {
  Link,
  useHistory,
  useLocation,
  useParams,
  Redirect,
  useNavigate,
} from "react-router-dom";
import Welcome from "../Welcome";

const Navigation = () => {
  const utils = useUtils();
  const location = useLocation();
  const navigate = useNavigate();

  const regex = /\/reporting\/\d+\/post\/\d+/;
  let tab = location && location.pathname.match(/\/([^/\d]+)$/);
  
  if (tab) tab = tab[1];
  if (!tab) tab = regex.test(location.pathname) ? "post" : "performance";

  const [state, setState] = React.useContext(AppContext);
  let currentPage = state.currentPage;
  let userID = state?.userData?.id;

  const params = useParams();
  //console.log("params", params);
  const { t, i18n } = useTranslation();

  //console.log('currentPage', currentPage);
  return (
    <>
      <ul className="md:flex-row  flex gap-16  ml-10">
        <li
          onClick={
            currentPage
              ? () => utils.goTo("/reporting/:pageId")
              : // ? () => navigate(`/reporting/${state?.currentPage?.page_id}/performance`)
                null
          }
          className={``}
        >
          <a
            className={`px-4 py-3 font-semibold text-[16px] cursor-pointer ${
              tab === "performance" || tab === "stats"
                ? "text-[#6941c6] border border-[#f9f5ff] rounded-[8px] bg-[#f9f5ff]"
                : "text-[#475467]"
            }`}
          >
            {t("Performance_report.performance_report")}
          </a>
        </li>
        
        {state?.currentPage?.social_type != "tiktok" &&
        <li
          onClick={
            currentPage
              ? () => utils.goTo("/reporting/:pageId/benchmark")
              : null
          }
        >
          <a
            className={`px-4 py-3 font-semibold text-[16px] cursor-pointer ${
              tab === "benchmark" || tab === "sector_posts"
                ? "text-[#6941c6] border border-[#f9f5ff] rounded-[8px] bg-[#f9f5ff]"
                : "text-[#475467]"
            }`}
          >
            {t("Benchmark.Benchmark_Report")}
          </a>
        </li>
        }

        <li
          onClick={
            currentPage ? () => utils.goTo("/reporting/:pageId/posts") : null
          }
        >
          <a
            className={`px-4 py-3 font-semibold text-[16px] cursor-pointer ${
              tab === "posts" || tab === "post"
                ? "text-[#6941c6] border border-[#f9f5ff] rounded-[8px] bg-[#f9f5ff]"
                : "text-[#475467]"
            }`}
          >
            {t("Post_Library.post_library")}
          </a>
        </li>
        
        {(userID === 133 || userID === 170) && (state?.currentPage?.social_type == "facebook") && (
        <li
          onClick={
            currentPage ? () => utils.goTo("/:pageId/planifier") : null
          }
        >
          <a
            className={`px-4 py-3 font-semibold text-[16px] cursor-pointer ${
              tab === "planifier"
                ? "text-[#6941c6] border border-[#f9f5ff] rounded-[8px] bg-[#f9f5ff]"
                : "text-[#475467]"
            }`}
          >
            {t("Planification.Planification")}
          </a>
        </li>
        )}
        {/* <li
          onClick={
            currentPage ? () => utils.goTo("/reporting/:pageId/brand") : null
          }
        >
          <a
            className={`px-4 py-3 font-semibold text-[16px] cursor-pointer ${
              tab === "brand"
              ? "text-[#6941c6] border border-[#f9f5ff] rounded-[8px] bg-[#f9f5ff]"
              : 'text-[#475467]'
            }`}
          >
            Brand Recap
          </a>
        </li> */}
      </ul>
    </>
  );
};

export default Navigation;
