import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { useTranslation } from "react-i18next";
import { Column } from 'primereact/column';

const MoyenneSectorielleYtb = (props) => {

    const { t } = useTranslation();

    const [header, setHeader] = useState([]);
    const [getData, setData] = useState([]);

    const data = props?.state?.reportingData?.ytb;

    const benchmark_video = data?.benchmark?.bench_video_ytb;
    const insight_date_bench = data?.benchmark?.insight_date_bench;

    //-- moy_sectorielle
    function nombreTotalJoursEntreDeuxDates(dateDebut, dateFin) {
        const debut = new Date(dateDebut);
        const fin = new Date(dateFin);
        const differenceEnMillisecondes = fin - debut;
        const differenceEnJours =
        Math.floor(differenceEnMillisecondes / (1000 * 60 * 60 * 24)) + 1;
        return differenceEnJours;
    }

    const start_date = props?.state?.reportingData?.date_info?.start_date ?? 0;
    const end_date = props?.state?.reportingData?.date_info?.end_date ?? 0;
    const totalJours = nombreTotalJoursEntreDeuxDates(start_date, end_date);
    
    // Parcourir chaque élément de video
    benchmark_video.forEach(video => {
        const activeData = Object.values(insight_date_bench).find(item => item.page_id === video.page_id);
        
        if (activeData) {
            video.active_date = activeData.active_date;
            video.username = activeData.username;
            video.followers = activeData.followers;
        } else {
            video.active_date = 'NaN';
            video.username = 'NaN';
            video.followers = '0';
        }
    });

    // Calculer le total d'engagement pour chaque entrée
    const totalEngagement = benchmark_video.reduce((acc, item) => {
        if (!item) return acc;
        return acc + 
               Number(item.likes_video || 0) + 
               Number(item.likes_shorts || 0) + 
               Number(item.views_shorts || 0) + 
               Number(item.views_video || 0) + 
               Number(item.comments_video || 0) + 
               Number(item.comments_shorts || 0);
    }, 0); 

    const totalFollowers = benchmark_video.reduce((sum, item) => {
        const followers = Number(item.followers);
        if (!isNaN(followers)) {
            return sum + followers;
        }
        return sum;  // Ignore les valeurs non valides
    }, 0);
    
    
    const followers = totalFollowers / benchmark_video.length;
    
    const TotalTauxEngagement = totalFollowers ? (totalEngagement / totalFollowers) * 100 : 0; 

    const TotalVideoCount = benchmark_video.reduce((sum, item) => {
        const video_count = (Number(item?.video || 0) + Number(item?.shorts || 0));
        
        if (!isNaN(video_count)) {
            return sum + video_count;
        }
        return sum; 
    }, 0);
    
    const video_count = TotalVideoCount / benchmark_video.length;

    const TotalVideos = benchmark_video.reduce((sum, item) => sum + Number(item.video), 0);
    const videos = TotalVideos / benchmark_video.length;

    const TotalShorts = benchmark_video.reduce((sum, item) => sum + Number(item.shorts), 0);
    const shorts = TotalShorts / benchmark_video.length;

    const engagement = totalEngagement / benchmark_video.length;
    
    const tauxEngagement = TotalTauxEngagement / benchmark_video.length;

    // Colonnes dynamiques
    const dynamicColumns = [
        { field: "number_marque", name: t("Benchmark.number_brands") },
        { field: "followers", name: t("Performance_report.Total_fans") },
        { field: "video", name: t("Benchmark.total_video") },
        { field: "total_engagement", name: "Total Engagement" },
        { field: "taux_engagement", name: "Taux Engagement" },
        { field: "video_per_day", name: t("Benchmark.videos_per_day") },
        { field: "videos", name: "Videos" },
        { field: "shorts", name: "Shorts" },
    ];
    
    // Transformation des données benchmark pour staticData
    const staticData = [
        {
            number_marque: benchmark_video.length,
            followers: (followers ?? 0).toLocaleString(),
            video: video_count.toLocaleString(),
            total_engagement: engagement.toLocaleString(), 
            taux_engagement: tauxEngagement.toFixed(2)+'%',
            video_per_day: (TotalVideoCount / totalJours).toFixed(2),
            videos: videos.toLocaleString(),
            shorts: shorts.toLocaleString()
        }
    ];

    useEffect(() => {
        setData(staticData);
        setHeader(dynamicColumns);
    }, []);

    return (
        <div className="table-header mt-8 p-5 bg-white rounded-[8px]">
            <div className="flex items-center w-full mb-4">
                <h2 className="text-[18px] font-semibold">{t("Benchmark.Sector_Averages")}</h2>
            </div>
            <DataTable value={getData}>
                {header.map((col, index) => (
                    <Column key={index} field={col.field} header={col.name}></Column>
                ))}
            </DataTable>
        </div>
    );
};

export default MoyenneSectorielleYtb;
