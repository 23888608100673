import React from 'react';
import html2canvas from "html2canvas";
import TopVideoYTB from './TopVideoYTB';
import { useTranslation } from "react-i18next";

const TopEngagement = (props) => {

    const { t } = useTranslation();

    const posts = props?.state?.reportingData?.ytb?.benchmark?.top_videos;   

    const printChart = (event) => {
        event.preventDefault();

        let target = event.target;
        let parent = target.parentElement.parentElement;
        let name = parent.getAttribute("data-value");
        parent.classList.add("capture_style");

        html2canvas(parent, {
        onclone: (document) => {},
        }).then((canvas) => {
        const data = canvas.toDataURL("image/jpg");
        const link = document.createElement("a");

        if (typeof link.download === "string") {
            link.href = data;
            link.download = name + ".jpg";

            parent.classList.remove("capture_style");

            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } else {
            window.open(data);
        }
        });
    };

    return (
        <div data-value={t("Benchmark.most_videos_interactions")} className="highchart_block1">
            <div data-html2canvas-ignore className="flex items-center justify-end">
                <button
                onClick={printChart}
                className="mx-5 w-5 h-5 print_capture"
                ></button>
            </div>
            <h2 className='text-[#101828] text-[18px] font-semibold'>{t("Benchmark.most_videos_interactions")}</h2>
            <p className='text-[#5a6872] text-[14px]'>{t("Benchmark.most_videos_interactions_txt")}</p>

            <TopVideoYTB posts={posts} />
        </div>
    );
};

export default TopEngagement;
