import React from "react";

import ReviewYtb from './YTB/ReviewYtb';
import TopEngagement from './YTB/TopEngagement';
import ChiffresKeysYtb from './YTB/ChiffresKeysYtb';
import CSEngagementYtb from './YTB/CSEngagementYtb';
import TypePublicationYtb from './YTB/TypePublicationYtb';
import MoyenneSectorielleYtb from './YTB/MoyenneSectorielleYtb';
import CroissanceFollowersYtb from './YTB/CroissanceFollowersYtb';
import InteractionVideoMoyenne from './YTB/InteractionVideoMoyenne';
import ComparaisonSectorielleYtb from './YTB/ComparaisonSectorielleYtb';

const BenchYtb = (props) => {
  
  return (
    <div>      
      <ReviewYtb state={props} />
      <MoyenneSectorielleYtb state={props} />
      <ChiffresKeysYtb state={props} />
      <CroissanceFollowersYtb state={props} />

      <div className="grid grid-cols-2 gap-4 p-5 rounded-[8px] my-5 bg-white">
        <ComparaisonSectorielleYtb state={props} />        
        <CSEngagementYtb state={props} />        
      </div>

      <TypePublicationYtb state={props} />
      <InteractionVideoMoyenne state={props} />
      <TopEngagement state={props} />

    </div>
  );
};

export default BenchYtb;
