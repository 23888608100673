import React from "react";
import { Skeleton } from "primereact/skeleton";

const MediaSkeleton = (props) => {
  let post = props.post;
  let pictrue_post = post.picture; // initialisation avec l'image par défaut

  // Vérifiez d'abord si post.link est valide et s'il s'agit d'un lien YouTube
  if (post.social_type && post.social_type.toLowerCase() === "ytb") {    
    if (!post.link) pictrue_post = ''
    let videoId;
    if (post.type_video === "shorts" && post.link) {
      // Extraire l'ID du vidéo YouTube Shorts
      videoId = post.link.split("/shorts/")[1]; 
    } else if (post.type_video === "video" && post.link) {
      // Extraire l'ID du vidéo YouTube classique
      videoId = post.link.split("v=")[1]?.split("&")[0]; 
    }
    if (videoId) {
      pictrue_post = `https://www.youtube.com/embed/${videoId}`;
    }
  }

  // Vérifiez si pictrue_post est défini avant d'afficher l'image ou la vidéo
  let media = null;

  // Vérifiez si le lien YouTube est valide pour afficher l'iframe
  if (post.social_type && post.social_type.toLowerCase() === "ytb" && pictrue_post) {
    media = (
      <iframe
        width="100%"
        height="224px"
        src={pictrue_post}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="YouTube video"
      ></iframe>
    );
  } else if ((post.social_type === "facebook" && post.status_type === "storie_video") || (post.type && post.type.toLowerCase() === "video")) {
    // Si c'est une vidéo de type Facebook ou une autre vidéo
    media = (
      <video
        className={"img_post mt-4 w-full " + (!post.status ? "hidden" : "")}
        onLoadedMetaData={props.onLoad}
        onLoadedData={props.onLoad}
        onError={props.onError}
        src={pictrue_post}
        controls
        muted
        autoPlay
        loop
      />
    );
  } else if (pictrue_post) {
    // Si c'est une image, afficher l'image
    media = (
      <img
        className={"img_post mt-4 w-full " + (!post.status ? "hidden" : "")}
        onLoad={props.onLoad}
        onError={props.onError}
        src={pictrue_post}
      />
    );
  }

  // Si pictrue_post est vide, afficher le message "No Media"
  if (!pictrue_post) {
    media = (
      <div style={{ width: "100%", height: 242, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <i>No Media</i>
      </div>
    );
  }

  return (
    <div className="grid formgrid mb-8 mt-2">
      <div className="field col-12 md:col-6 w-full">
        <div className="custom-skeleton">
          {post.status === "loaded" || !post.status ? media : null}

          {post.status !== "loaded" && pictrue_post && post.social_type.toLowerCase() != "ytb" ? (
            <Skeleton width="100%" height="224px"></Skeleton>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default MediaSkeleton;
