import React from "react";
import { useTranslation } from "react-i18next";
import Highcharts from "highcharts";
import html2canvas from "html2canvas";
import HighchartsReact from "highcharts-react-official";

const TypePublicationYtb = (props) => {
    
    const { t } = useTranslation();

    const printChart = (event) => {
        event.preventDefault();

        let target = event.target;
        let parent = target.parentElement.parentElement;
        let name = parent.getAttribute("data-value");
        parent.classList.add("capture_style");

        html2canvas(parent, {
        onclone: (document) => {},
        }).then((canvas) => {
        const data = canvas.toDataURL("image/jpg");
        const link = document.createElement("a");

        if (typeof link.download === "string") {
            link.href = data;
            link.download = name + ".jpg";

            parent.classList.remove("capture_style");

            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } else {
            window.open(data);
        }
        });
    };

    let options = {
        chart: {
            type: 'column',
            style: {
                fontFamily: "Inter",
            },
        },
        title: {
            text: t('Performance_report.post_type'),
            align: "left",
            margin: 50,
        },
        subtitle: {
            text: t('Performance_report.post_type_desc'),
            align: "left",
            margin: 50,
        },
        xAxis: {
            categories: []
        },
        yAxis: {
            min: 0,
            title: {
                text: ''
            }
        },
        tooltip: {
            enabled: false,
        },
        credits: {
            enabled: false,
        },
        plotOptions: {
            column: {
                stacking: "normal",
                dataLabels: {
                enabled: true,
                },
            },
        },
        series: [{}]
    };

    const data_bench = props?.state?.reportingData?.ytb?.benchmark?.bench_video_ytb;

    const video = data_bench.map((item) => +item?.video ?? 0)
    const videoArray = {
        name: "Videos",
        data: video,
    };

    const shorts = data_bench.map((item) => +item?.shorts ?? 0)
    const shortsArray = {
        name: "Shorts",
        data: shorts,
    };    

    options.series.push(videoArray);
    options.series.push(shortsArray);
    data_bench.map((entry) => {
        options.xAxis.categories.push(entry.name_page);
    });

    function remove_empty_value(arr) {
        var index;
        arr.some(function (obj, i) {
        return Object.keys(obj).length === 0 && obj.constructor === Object
            ? ((index = i), true)
            : false;
        });
        arr.shift(index);
        return index;
    }
    remove_empty_value(options.series);

    return (
        <div>
        <div className="highchart_block">
            <div data-value={t("Performance_report.post_type")} className="highchart_block1">
            <div data-html2canvas-ignore className="flex items-center justify-end">
                <button
                onClick={printChart}
                className="mx-5 w-5 h-5 print_capture"
                ></button>
            </div>

            <HighchartsReact highcharts={Highcharts} options={options} />

            <div className="capture_style"></div>
            </div>
        </div>
        </div>
    );
};

export default TypePublicationYtb;
