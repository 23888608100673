import React from "react";
import { Routes, Route, Navigate, useParams } from "react-router-dom";

import { AppContext } from "../utils/Context";
import Layout from "../cmp/Layout";
import Welcome from "../cmp/Welcome";
import Maintenance from "../cmp/Maintenance";
import Dashboard from "../cmp/Dashboard/Dashboard";
import Planification from "../cmp/Planification/Planification";
import Planifications from "../cmp/Planification/Planifications";

import UploadYtb from "../cmp/Planification/UploadYtb";
import UploadReel from "../cmp/Planification/UploadReel";
import UploadFeed from "../cmp/Planification/UploadFeed";
import UploadPhoto from "../cmp/Planification/UploadPhoto";
import UploadVideo from "../cmp/Planification/UploadVideo";
import UploadStorie from "../cmp/Planification/UploadStorie";


import CreatePost from "../cmp/Planification/CreatePost";
import Login from "../cmp/Login";
import MotCle from "../cmp/MotCle";
import Profil from "../cmp/Profil";
import Abonnements from "../cmp/Abonnements";
import PerformanceReport from "../cmp/Reporting/PerformanceReport/PerformanceReport";
import PostLibrary from "../cmp/Reporting/PostLibrary";
import PostDetail from "../cmp/Reporting/PostDetail";
import PostScraping from "../cmp/Reporting/PostScraping";
import TendanceDetail from "../cmp/Reporting/TendanceDetail";
import BenchmarkReport from "../cmp/Reporting/BenchMark";
import BenchmarkPosts from "../cmp/Reporting/BenchmarkPosts";
import BenchmarkADS from "../cmp/Reporting/BenchmarkADS";
import AllStatsOverview from "../cmp/Reporting/PerformanceReport/AllStatsOverview";
import BrandMeter from "../cmp/Reporting/PerformanceReport/BrandRecap/BrandMeter";
import SignUp from "../cmp/SignUp";
import SetupAccount from "../cmp/SetupAccount";
import SetupPages from "../cmp/SetupPages";
import WelcomePage from "../cmp/WelcomePage";
import PostModal from "../cmp/Planification/PostModal";
import PageManagement from "../cmp/PageManagement";
import ForgotPassword from "../cmp/ForgotPassword";
import ResetPassword from "../cmp/ResetPassword";

const Routers = () => {
  const [state, setState] = React.useContext(AppContext);
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route
        path="*"
        element={
          <RoutesProtected auth={state.token}>
            <Layout />
          </RoutesProtected>
        }
      />
      <Route path="/SignUp" element={<SignUp />} />
      <Route path="/setup/account" element={<SetupAccount />} />
      <Route path="/setup/pages" element={<SetupPages />} />
      <Route path="/forgotpassword" element={<ForgotPassword />} />
      <Route path="/resetsPassword" element={<ResetPassword/>} />

      {/* <Route path="/welcome" element={<Welcome />} /> */}

    </Routes>
  );
};

const AppRoutes = (props) => {
  const [state, setState] = React.useContext(AppContext);
  if (!state || !state.token) return <Navigate to="/login" />;
  const id_user = state?.userData?.id;
  
  const exclude_users = [170, 133, 202, 199, 188, 208, 263, 128, 191, 124];
  if (!exclude_users.includes(id_user)) return <Maintenance />;   
  
  return (    
    <Routes>
      <Route path="/welcome" element={<Welcome />} />
      <Route path="/reporting/:pageId" element={<Dashboard {...props} />} />
    
    {/* Conditionally render the planification routes if id_user == 133 */}
    {id_user === 133 && (
      <>
        <Route path="/planification/:pageId" element={<Planification />} />
        <Route path="/planification" element={<Planification />} />
        <Route path="/:pageId/planifier" element={<CreatePost {...state} />} />
        
        <Route path="/uploadytb" element={<UploadYtb {...state} />} />
        <Route path="/uploadreel" element={<UploadReel {...state} />} />
        <Route path="/uploadfeed" element={<UploadFeed {...state} />} />
        <Route path="/uploadphoto" element={<UploadPhoto {...state} />} />
        <Route path="/uploadvideo" element={<UploadVideo {...state} />} />
        <Route path="/uploadstorie" element={<UploadStorie {...state} />} />

        <Route path="/:pageId/createPost" element={<CreatePost {...state} />} />
      </>
    )}

      <Route path="/pages" element={<PageManagement />} />
      <Route path="/mot_cle/:pageId" element={<MotCle {...state} />} />
      <Route path="/profile" element={<Profil {...state} />} />
      <Route path="/abonnements" element={<Abonnements {...state} />} />
      <Route path="/reporting/:pageId/tendance/:word" element={<TendanceDetail />}
/>

      {/* <Route path="/postmodal" element={<PostModal />} /> */}

      {/*  <Route  path="/profile" element={Profile} />
        <Route  path="/pages" element={ManagePages} /> */}
      {/* <Route
        path="/reporting/:pageId/performance"
        //element={() => <Welcome/>}
        // element={() => <PerformanceReportStatic {...props} />}
        // element={() => <BenchmarkReportStatic {...props} />}
        element={ <PerformanceReport {...props} />}
      /> */}

      <Route path="*" element={<Dashboard />} />
    </Routes>
  );
};

const ReportingRoutes = (props) => {
  const [state, setState] = React.useContext(AppContext); 

  const social_type = state?.currentPage?.social_type;

  // const id_user = state?.userData?.id;  
  // const exclude_users = [170, 133, 202, 199, 188, 208, 263];  
  // if (!exclude_users.includes(id_user)) return <Maintenance />; 

  
  return (
    <Routes>
      <Route
        path="/reporting/:pageId/performance"
        //element={() => <Welcome/>}
        // element={() => <PerformanceReportStatic {...props} />}
        // element={() => <BenchmarkReportStatic {...props} />}
        element={<PerformanceReport {...props} />}
      />

      <Route
        path="/reporting/:pageId/benchmark"
        element={<BenchmarkReport {...props} />}
      />
      <Route
        path="/reporting/:pageId/benchmark/sector_posts"
        element={<BenchmarkPosts />}
      />
      <Route
        path="/reporting/:pageId/benchmark/sector_ads"
        element={<BenchmarkADS />}
      />

      { social_type == "ytb" ? (
        <Route
          path="/reporting/:pageId/posts"
          element={<PostScraping {...props} />}
        />
      ) : (
        <Route
          path="/reporting/:pageId/posts"
          element={<PostLibrary {...props} />}
        />
      )}

      <Route
        path="/reporting/:pageId/post/:postId"
        element={<PostDetail />}
      />

      {/* <Route
        path="/reporting/:pageId/posts"
        element={<PageManagement {...props} />}
      /> */}

      <Route
        path="/reporting/:pageId/stats"
        element={<AllStatsOverview {...props} />}
      />

      <Route
        path="/reporting/:pageId/brand"
        element={<BrandMeter {...props} />}
      />

      <Route path="*" element={<PerformanceReport {...props} />} />
    </Routes>
  );
};

const RoutesProtected = ({ auth, children }) => {
  const [state, setState] = React.useContext(AppContext);

  if (!auth) {
    return <Navigate to="/login" replace />;
  }

  return children;
};

export { Routers, AppRoutes, ReportingRoutes };
