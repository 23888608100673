import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { useTranslation } from "react-i18next";
import { AppContext } from "../../../utils";
import { Column } from 'primereact/column';

import { InputText } from 'primereact/inputtext';

const ReviewYtb = (props) => {

    const { t } = useTranslation();
    const [state] = React.useContext(AppContext);

    const [header, setHeader] = useState([]);
    const [getData, setData] = useState([]);
    const [globalFilter, setGlobalFilter] = useState(null);
    
    const data = props?.state?.reportingData?.ytb;   
  
    const current_page = state?.currentPage;
    const benchmark_video = data?.benchmark?.bench_video_ytb;
    const active_date_bench = data?.benchmark?.active_date_bench;
    const insight_date_bench = data?.benchmark?.insight_date_bench;

    //-- moy_sectorielle
    function nombreTotalJoursEntreDeuxDates(dateDebut, dateFin) {
        const debut = new Date(dateDebut);
        const fin = new Date(dateFin);
        const differenceEnMillisecondes = fin - debut;
        const differenceEnJours =
        Math.floor(differenceEnMillisecondes / (1000 * 60 * 60 * 24)) + 1;
        return differenceEnJours;
    }

    const start_date = props?.state?.reportingData?.date_info?.start_date ?? 0;
    const end_date = props?.state?.reportingData?.date_info?.end_date ?? 0;
    const totalJours = nombreTotalJoursEntreDeuxDates(start_date, end_date);   

    // Parcourir chaque élément de video
    benchmark_video.forEach(video => {
        const activeData = Object.values(active_date_bench).find(item => item.page_id === video.page_id);
        const insightData = Object.values(insight_date_bench).find(item => item.page_id === video.page_id);
        if (activeData) {
            video.active_date = activeData.active_date;
        } else {
            video.active_date = 'NaN';
        }
        if (insightData) {
            video.username = insightData.username;
            video.followers = insightData.followers;
        } else {
            video.username = 'NaN';
            video.followers = '0';
        }
    });
    
    // Colonnes dynamiques
    const dynamicColumns = [
        { field: "name_page", name: t("Benchmark.marque") },
        { field: "followers", name: t("Performance_report.Total_fans") },
        { field: "video", name: t("Benchmark.total_video") },
        { field: "total_engagement", name: "Total Engagement" },
        { field: "taux_engagement", name: "Taux Engagement" },
        { field: "video_per_day", name: t("Benchmark.videos_per_day") },
        { field: "videos", name: "Videos" },
        { field: "shorts", name: "Shorts" },
        { field: "active_date", name: t("Performance_report.active_date") },
    ];

    // Transformation des données benchmark pour staticData
    const staticData = benchmark_video.map((item, index) => {
        // Vérifiez si l'élément est valide
        if (!item) {
            console.warn("Élément invalide dans benchmark_video :", item);
            return {};
        }

        const totalEngagement = 
            Number(item.likes_video || 0) + 
            Number(item.likes_shorts || 0) + 
            Number(item.views_shorts || 0) + 
            Number(item.views_video || 0) + 
            Number(item.comments_video || 0) + 
            Number(item.comments_shorts || 0);
        
        const tauxEngagement = item.followers > 0 ? (totalEngagement / item.followers) * 100 : 0;
                
        const video_count = (Number(item?.video || 0) + Number(item?.shorts || 0));
        
        const video_per_day = video_count ? video_count / totalJours : 0;
    
        return {
            id: item.page_id,
            name_page: item.name_page,
            followers: item.followers?.toLocaleString() ?? 0,
            video: video_count?.toLocaleString() ?? 0,
            total_engagement: totalEngagement.toLocaleString(),
            taux_engagement: tauxEngagement ? tauxEngagement.toFixed(2) + '%' : 0,
            video_per_day: video_per_day.toFixed(2),
            videos: item.video?.toLocaleString(),
            shorts: item.shorts?.toLocaleString(),
            active_date: item.active_date,
        };
    });

    useEffect(() => {
        setData(staticData);
        setHeader(dynamicColumns);
    }, []);

    const searchInput = (
        <div className="table-header">
            <div data-value={t("Benchmark.summary")} className="flex items-center w-full">
                <div className="flex flex-col">
                    <h1 className="mx-0 my-1 text-[24px]">{t("Benchmark.summary")}</h1>
                    <p>{t("Benchmark.summary_desc")}</p>
                </div>
                <div className="ml-auto">
                    <span className="p-input-icon-left items-right">
                        <i className="pi pi-search" />
                        <InputText
                            type="search"
                            onInput={(e) => setGlobalFilter(e.target.value)}
                            placeholder={t("Options.search")}
                        />
                    </span>
                </div>
            </div>
        </div>
    );

    return (
        <DataTable value={getData} header={searchInput} globalFilter={globalFilter} className='mt-8' rowClassName={(data) => data.id === current_page.page_id ? '!bg-[#dee2e6] font-semibold' : ''}>
            {header.map((col, index) => (
                <Column key={index} field={col.field} header={col.name} sortable></Column>
            ))}
        </DataTable>
    );
};

export default ReviewYtb;
